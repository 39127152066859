import { useState, MouseEvent } from 'react';

import { IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import logo2 from '../assets/images/logo.png';
import Testimonials from './Testimonials';
import Benefits from './Benefits';
import Examples from './Examples';
import Slogan from './Slogan';
import Nav from './SideNav';
import Intro from './Intro';
import Services from './Services';
import Footer from './Footer';
import RequestForm from './RequestForm';

import useStyles from './styles';
// import LatesBlogs from './LatestBlog';

function Home() {
  console.log('here')
  const [isOpen, setIsOpen] = useState(false);
  const [idx, setIdx] = useState(-1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const styles = useStyles();

  const toggleDrawer = () => setIsOpen(prev => !prev);
  const selectRoutInfoIndex = (e: MouseEvent) => {
    const idx = Number((e.currentTarget as HTMLElement).dataset.id);
    setIdx(prev => prev === idx ? -1 : idx);
  };
  return (
    <div>
      <div className={styles.headerWrapper}>
        <header className={styles.header}>
          <img src={logo2} alt="amaros logo" className={styles.logo} />
          <Button
            variant="outlined"
            size="medium"
            sx={{ borderRadius: '0px' }}
            color="warning"
            className={styles.requestButton}
            onClick={() => setModalIsOpen(true)}
          >
            REQUEST DEMO
          </Button>
          <IconButton onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        </header>

      </div>
      <Nav isOpen={isOpen} idx={idx} selectRoutInfoIndex={selectRoutInfoIndex} toggleDrawer={toggleDrawer} openRequestDemo={() => setModalIsOpen(true)} />
      <Intro openRequestDemo={() => setModalIsOpen(true)} />
      <Slogan />
      <Benefits />
      <Examples />
      {/* <Steps /> */}
      <Services />
      <Testimonials />
      {/* <LatesBlogs /> */}
      <Footer openRequestDemo={() => setModalIsOpen(true)} />
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <RequestForm closeForm={() => setModalIsOpen(false)} />
      </Modal>
    </div>
  );
}

export default Home;
