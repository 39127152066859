import { useMemo } from "react";

function Hospital() {
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)'), []);

  return (
    <svg
      width={isMobile ? "333" : "653"}
      height={isMobile ? "333" : "475"}
      viewBox="0 0 653 475"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: 'translate(0px, -6.39488e-14px)',
        filter: 'sepia(50%) contrast(120%)',
      }}
    >
      <g opacity="0.15" filter="url(#filter0_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M272.21 38.8166L287.528 49.3643L377.323 71.2539L333.073 110.671L401.781 125.738L346.637 186.076L262.678 168.636L165.882 232.099L95.4484 225.696L69.7839 199.472L187.184 118.474L272.21 38.8166Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M234.715 138.845L295.653 103.299L300.953 92.0455V37.0083L254.789 48.131L172.907 48.2613V94.6658L234.715 138.845Z" fill="url(#paint0_linear)" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="172" y="37" width="129" height="102">
        <path fillRule="evenodd" clipRule="evenodd" d="M234.715 138.845L295.653 103.299L300.953 92.0455V37.0083L254.789 48.131L172.907 48.2613V94.6658L234.715 138.845Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M240.808 93.3467V104.745L292.954 75.4521V63.4505L240.808 93.3467Z" fill="#0471B4" />
        <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="240" y="63" width="53" height="42">
          <path fillRule="evenodd" clipRule="evenodd" d="M240.808 93.3467V104.745L292.954 75.4521V63.4505L240.808 93.3467Z" fill="white" />
        </mask>
        <g mask="url(#mask1)">
          <path fillRule="evenodd" clipRule="evenodd" d="M316.577 73.1112V88.8257L264.431 59.533V43.2151L316.577 73.1112Z" fill="#0682C3" />
          <path fillRule="evenodd" clipRule="evenodd" d="M236.605 90.9828V102.381L288.751 73.0883V61.0867L236.605 90.9828Z" fill="#7BBAE5" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M240.808 110.612V122.01L292.954 92.7175V80.7159L240.808 110.612Z" fill="#0471B4" />
        <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="240" y="80" width="53" height="43">
          <path fillRule="evenodd" clipRule="evenodd" d="M240.808 110.612V122.01L292.954 92.7175V80.7159L240.808 110.612Z" fill="white" />
        </mask>
        <g mask="url(#mask2)">
          <g opacity="0.817825" filter="url(#filter1_f)">
            <path fillRule="evenodd" clipRule="evenodd" d="M240.808 110.612V122.01L292.954 92.7175V80.7159L240.808 110.612Z" fill="#F1A038" />
          </g>
          <path fillRule="evenodd" clipRule="evenodd" d="M316.577 90.3767V106.091L264.431 76.7985V60.4805L316.577 90.3767Z" fill="#0682C3" />
          <path fillRule="evenodd" clipRule="evenodd" d="M236.605 108.248V119.646L288.751 90.3537V78.3521L236.605 108.248Z" fill="#FFAE46" />
        </g>
        <rect x="296.1" y="31.0778" width="23.439" height="84.2218" fill="#017FC3" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M234.687 82.8742L295.653 48.2613L300.953 37.0083L295.653 25.1717L254.789 1.72656L172.907 48.2613L234.687 82.8742Z" fill="url(#paint1_linear)" />
      <mask id="mask3" mask-type="alpha" maskUnits="userSpaceOnUse" x="172" y="1" width="129" height="82">
        <path fillRule="evenodd" clipRule="evenodd" d="M234.687 82.8742L295.653 48.2613L300.953 37.0083L295.653 25.1717L254.789 1.72656L172.907 48.2613L234.687 82.8742Z" fill="white" />
      </mask>
      <g mask="url(#mask3)">
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M187.179 56.7779L187.179 51.5706L237.899 31.0778L268.468 39.9549V45.1345L217.875 74.2414L187.179 56.7779Z" fill="#0178BE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M187.179 51.5983L237.899 22.4451L268.468 39.9548L217.875 69.0618L187.179 51.5983Z" fill="url(#paint2_linear)" />
      <mask id="mask4" mask-type="alpha" maskUnits="userSpaceOnUse" x="187" y="22" width="82" height="48">
        <path fillRule="evenodd" clipRule="evenodd" d="M187.179 51.5983L237.899 22.4451L268.468 39.9548L217.875 69.0618L187.179 51.5983Z" fill="white" />
      </mask>
      <g mask="url(#mask4)">
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M114.25 35.6141V129.038L175.549 163.959L236.849 129.038V35.6141L175.262 0L114.25 35.6141Z" fill="url(#paint3_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M175.55 172.592L236.849 137.671V35.6141L175.262 0L175.55 172.592Z" fill="url(#paint4_linear)" />
      <g opacity="0.284784" filter="url(#filter2_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M194.713 73.3538V79.8542L211.683 69.9919V63.504L194.713 73.3538Z" fill="#00478A" />
      </g>
      <g opacity="0.284784" filter="url(#filter3_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M200.551 63.504L206.1 60.0604V79.8542L200.551 82.8411V63.504Z" fill="#00478A" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M197.92 73.754V80.2543L214.89 70.3921V63.9042L197.92 73.754Z" fill="#0178BE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M203.758 63.9042L209.308 60.4605V80.2543L203.758 83.2413V63.9042Z" fill="#0178BE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M199.123 74.1542V80.6545L216.093 70.7923V64.3044L199.123 74.1542Z" fill="#EDF7FF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M204.961 64.3044L210.511 60.8607V80.6545L204.961 83.6415V64.3044Z" fill="#EDF7FF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M188.756 101.548V112.946L222.932 93.5462V81.9542L188.756 101.548Z" fill="#0471B4" />
      <mask id="mask5" mask-type="alpha" maskUnits="userSpaceOnUse" x="188" y="81" width="35" height="32">
        <path fillRule="evenodd" clipRule="evenodd" d="M188.756 101.548V112.946L222.932 93.5462V81.9542L188.756 101.548Z" fill="white" />
      </mask>
      <g mask="url(#mask5)">
        <path fillRule="evenodd" clipRule="evenodd" d="M251.984 94.2614V109.976L199.838 80.6832V64.3652L251.984 94.2614Z" fill="#0682C3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M184.553 99.1839V110.582L218.859 91.3112V76.1878L184.553 99.1839Z" fill="#FFAE46" />
        <g opacity="0.817825" filter="url(#filter4_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M188.756 101.548V112.946L222.932 93.5462V81.9542L188.756 101.548Z" fill="#F1A038" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M188.756 118.813V165.211L222.932 145.812V99.2196L188.756 118.813Z" fill="#0471B4" />
      <mask id="mask6" mask-type="alpha" maskUnits="userSpaceOnUse" x="188" y="99" width="35" height="67">
        <path fillRule="evenodd" clipRule="evenodd" d="M188.756 118.813V165.211L222.932 145.812V99.2196L188.756 118.813Z" fill="white" />
      </mask>
      <g mask="url(#mask6)">
        <path fillRule="evenodd" clipRule="evenodd" d="M251.984 111.527V162.241L199.838 132.949V81.6307L251.984 111.527Z" fill="#0682C3" />
        <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M184.553 116.449V162.847L218.859 143.577V93.4532L184.553 116.449Z" fill="#7BBAE5" />
        <mask id="mask7" mask-type="alpha" maskUnits="userSpaceOnUse" x="184" y="93" width="35" height="70">
          <path fillRule="evenodd" clipRule="evenodd" d="M184.553 116.449V162.847L218.859 143.577V93.4532L184.553 116.449Z" fill="white" />
        </mask>
        <g mask="url(#mask7)">
          <path d="M204.404 104.982V155.799" stroke="#7BBAE5" />
          <path d="M207.701 130.39L212.483 128.027" stroke="#7BBAE5" strokeLinecap="round" />
          <path d="M196.924 135.621L201.706 133.257" stroke="#7BBAE5" strokeLinecap="round" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M113.963 35.6141L174.975 70.6149L236.849 35.6141L174.975 0L113.963 35.6141Z" fill="url(#paint5_linear)" />
      <mask id="mask8" mask-type="alpha" maskUnits="userSpaceOnUse" x="113" y="0" width="124" height="71">
        <path fillRule="evenodd" clipRule="evenodd" d="M113.963 35.6141L174.975 70.6149L236.849 35.6141L174.975 0L113.963 35.6141Z" fill="white" />
      </mask>
      <g mask="url(#mask8)">
        <path fillRule="evenodd" clipRule="evenodd" d="M113.963 35.214L174.975 70.2147L236.849 35.214L174.975 -0.400146L113.963 35.214Z" fill="url(#paint6_linear)" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.3491 176.191L114.368 129.242L175.871 163.792L114.368 199.62L93.8681 204.692L73.1355 199.62L32.3491 176.191Z" fill="#BEDDF9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.3491 184.824V127.698L175.871 115.3V172.425L114.368 208.253L93.8681 213.325L73.1355 208.253L32.3491 184.824Z" fill="url(#paint7_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M124.036 161.114V172.512L176.183 143.219V131.217L124.036 161.114Z" fill="#0471B4" />
      <mask id="mask9" mask-type="alpha" maskUnits="userSpaceOnUse" x="124" y="131" width="53" height="42">
        <path fillRule="evenodd" clipRule="evenodd" d="M124.036 161.114V172.512L176.183 143.219V131.217L124.036 161.114Z" fill="white" />
      </mask>
      <g mask="url(#mask9)">
        <path fillRule="evenodd" clipRule="evenodd" d="M199.806 140.878V156.593L147.66 127.3V110.982L199.806 140.878Z" fill="#0682C3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M119.833 158.75V170.148L171.98 140.855V128.854L119.833 158.75Z" fill="#7BBAE5" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M124.036 178.379V189.777L176.183 160.484V148.483L124.036 178.379Z" fill="#0471B4" />
      <mask id="mask10" mask-type="alpha" maskUnits="userSpaceOnUse" x="124" y="148" width="53" height="42">
        <path fillRule="evenodd" clipRule="evenodd" d="M124.036 178.379V189.777L176.183 160.484V148.483L124.036 178.379Z" fill="white" />
      </mask>
      <g mask="url(#mask10)">
        <path fillRule="evenodd" clipRule="evenodd" d="M199.806 158.144V173.858L147.66 144.565V128.247L199.806 158.144Z" fill="#0682C3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M119.833 176.015V187.413L171.98 158.121V146.119L119.833 176.015Z" fill="#7BBAE5" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.3491 184.824V127.698L93.7438 156.199L114.368 208.253L93.8681 213.325L73.1355 208.253L32.3491 184.824Z" fill="url(#paint8_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M32.3491 127.698L114.368 80.749L175.502 115.3L114.368 151.128L93.8681 156.199L73.1355 151.128L32.3491 127.698Z" fill="url(#paint9_linear)" />
      <mask id="mask11" mask-type="alpha" maskUnits="userSpaceOnUse" x="32" y="80" width="144" height="77">
        <path fillRule="evenodd" clipRule="evenodd" d="M32.3491 127.698L114.368 80.749L175.502 115.3L114.368 151.128L93.8681 156.199L73.1355 151.128L32.3491 127.698Z" fill="white" />
      </mask>
      <g mask="url(#mask11)">
        <path fillRule="evenodd" clipRule="evenodd" d="M32.3491 127.298L114.368 80.3488L175.502 114.899L114.368 150.727L93.8681 155.799L73.1355 150.727L32.3491 127.298Z" fill="url(#paint10_linear)" />
        <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M107.588 134.517H161.942L191.213 118.245L155.768 91.1211L109.048 120.555L107.588 134.517Z" fill="#4AAAEF" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M73.1355 151.128V208.253L93.8682 213.325V156.199L73.1355 151.128Z" fill="#7FC3ED" />
      <path fillRule="evenodd" clipRule="evenodd" d="M114.477 151.128V208.253L93.7439 213.325V156.199L114.477 151.128Z" fill="#51AAE2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76.8918 117.054L76.8918 111.847L127.612 91.3537L158.181 100.231V105.41L107.588 134.517L76.8918 117.054Z" fill="#0178BE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76.8918 117.054L76.8918 111.846L107.588 127.214V134.517L76.8918 117.054Z" fill="#74B6E0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M76.8918 111.874L127.612 82.7209L158.181 100.231L107.588 129.338L76.8918 111.874Z" fill="url(#paint11_linear)" />
      <g opacity="0.224842" filter="url(#filter5_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M29.642 139.058L44.3725 145.028L56.355 174.697L55.9067 201.43L42.9985 209.01L22.2932 183.542L29.642 139.058Z" fill="#2979AC" />
      </g>
      <g opacity="0.224842" filter="url(#filter6_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M57.4357 166.342L72.1662 172.312L84.1487 201.981L83.7004 228.715L70.7922 236.294L50.0869 210.826L57.4357 166.342Z" fill="#2979AC" />
      </g>
      <g opacity="0.440267" filter="url(#filter7_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M289.713 132.784L367.642 137.347L291.982 112.775L289.713 132.784Z" fill="#0E0B0B" />
      </g>
      <g opacity="0.520066" filter="url(#filter8_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M289.315 136.757C300.356 136.757 309.306 132.575 309.306 127.416C309.306 122.256 300.356 118.074 289.315 118.074C278.275 118.074 269.325 122.256 269.325 127.416C269.325 132.575 278.275 136.757 289.315 136.757Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M314.387 126.822C316.631 124.909 316.98 121.755 315.766 119.068L295.001 73.1389C294.523 72.0812 293.02 72.0822 292.543 73.1406L271.853 119.072C270.644 121.757 270.995 124.906 273.235 126.817C277 130.029 283.915 134.364 293.814 134.364C303.707 134.364 310.62 130.034 314.387 126.822Z" fill="url(#paint12_linear)" />
      <mask id="mask12" mask-type="alpha" maskUnits="userSpaceOnUse" x="271" y="72" width="46" height="63">
        <path fillRule="evenodd" clipRule="evenodd" d="M314.387 126.822C316.631 124.909 316.98 121.755 315.766 119.068L295.001 73.1389C294.523 72.0812 293.02 72.0822 292.543 73.1406L271.853 119.072C270.644 121.757 270.995 124.906 273.235 126.817C277 130.029 283.915 134.364 293.814 134.364C303.707 134.364 310.62 130.034 314.387 126.822Z" fill="white" />
      </mask>
      <g mask="url(#mask12)">
        <g filter="url(#filter9_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M311.095 114.356C303.92 114.356 298.104 106.858 298.104 97.6085C298.104 88.3594 303.92 80.8615 311.095 80.8615C318.27 80.8615 324.086 88.3594 324.086 97.6085C324.086 106.858 318.27 114.356 311.095 114.356Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter10_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M277.515 109.89C270.341 109.89 264.524 102.392 264.524 93.1426C264.524 83.8935 270.341 76.3956 277.515 76.3956C284.69 76.3956 290.506 83.8935 290.506 93.1426C290.506 102.392 284.69 109.89 277.515 109.89Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter11_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M311.095 103.756C311.095 103.756 293.618 113.302 273.308 111.596L272.869 96.4816H303.116L311.095 103.756Z" fill="#C23A00" />
        </g>
        <g filter="url(#filter12_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M295.183 108.098C295.183 108.098 288.019 121.269 267.71 119.563L272.869 96.4816C272.869 96.4816 303.116 96.4816 303.116 96.4816C303.116 96.4816 295.183 108.098 295.183 108.098Z" fill="#C23A00" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M310.648 103.606C312.213 102.075 312.414 99.7389 311.512 97.7436L294.97 61.156C294.492 60.0982 292.99 60.0993 292.513 61.1576L276.031 97.7468C275.133 99.7405 275.335 102.073 276.898 103.602C279.823 106.467 285.499 110.631 293.775 110.631C302.046 110.631 307.721 106.471 310.648 103.606Z" fill="url(#paint13_linear)" />
      <mask id="mask13" mask-type="alpha" maskUnits="userSpaceOnUse" x="275" y="60" width="38" height="51">
        <path fillRule="evenodd" clipRule="evenodd" d="M310.648 103.606C312.213 102.075 312.414 99.7389 311.512 97.7436L294.97 61.156C294.492 60.0982 292.99 60.0993 292.513 61.1576L276.031 97.7468C275.133 99.7405 275.335 102.073 276.898 103.602C279.823 106.467 285.499 110.631 293.775 110.631C302.046 110.631 307.721 106.471 310.648 103.606Z" fill="white" />
      </mask>
      <g mask="url(#mask13)">
      </g>
      <path d="M479 312L612 233.143L517.539 180" stroke="url(#paint14_linear)" strokeWidth="1.24674" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="4.04" />
      <g filter="url(#filter13_f)">
        <path d="M275.951 222.818L315.331 245.224C318.649 247.112 322.738 247.017 325.965 244.976L422.213 184.122" stroke="#020D1C" strokeWidth="6.24674" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g opacity="0.282227" filter="url(#filter14_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M334.945 257.69C344.53 257.69 352.301 255.851 352.301 253.582C352.301 251.313 344.53 249.473 334.945 249.473C325.36 249.473 317.589 251.313 317.589 253.582C317.589 255.851 325.36 257.69 334.945 257.69Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M322.351 255.413C322.351 255.413 322.819 255.685 323.047 255.649C324.898 255.357 326 254.892 326.311 254.958C326.835 255.068 327.012 255.261 325.916 256.175C324.821 257.089 323.696 257.857 322.756 257.659C321.815 257.46 322.351 255.413 322.351 255.413Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M317.713 253.955C317.713 253.955 317.465 255.009 317.713 255.468C317.96 255.927 318.577 255.993 319.197 255.635C319.511 255.454 320.171 254.804 320.586 254.122C320.988 253.46 321.15 252.763 321.217 252.676C321.354 252.498 320.913 252.212 320.709 252.212C320.505 252.212 317.713 253.955 317.713 253.955Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M328.665 233.102C328.665 233.102 330.021 231.732 330.021 232.286C330.021 232.84 330.96 232.327 331.229 232.584C331.498 232.84 330.83 233.953 330.402 233.953C329.974 233.953 328.551 233.636 328.551 233.636L328.665 233.102Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M318.686 225.736C318.686 225.736 315.762 229.247 315.762 230.542C315.762 231.837 317.481 234.866 317.481 234.866L320.33 229.765L318.686 225.736Z" fill="#EF6F28" />
      <path fillRule="evenodd" clipRule="evenodd" d="M322.699 222.025C322.699 222.025 322.938 223.918 322.699 224.458C322.46 224.998 321.765 224.552 321.46 224.978C321.155 225.404 321.383 226.712 321.383 226.712L317.589 226.928L318.804 225.679C319.147 225.326 319.363 224.87 319.419 224.382L319.656 222.324L322.699 222.025Z" fill="#F49F95" />
      <mask id="mask14" mask-type="alpha" maskUnits="userSpaceOnUse" x="317" y="222" width="6" height="5">
        <path fillRule="evenodd" clipRule="evenodd" d="M322.699 222.025C322.699 222.025 322.938 223.918 322.699 224.458C322.46 224.998 321.765 224.552 321.46 224.978C321.155 225.404 321.383 226.712 321.383 226.712L317.589 226.928L318.804 225.679C319.147 225.326 319.363 224.87 319.419 224.382L319.656 222.324L322.699 222.025Z" fill="white" />
      </mask>
      <g mask="url(#mask14)">
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M319.287 224.332C319.438 224.576 319.538 224.393 319.934 224.393C320.329 224.393 320.329 224.582 320.785 224.629C321.011 224.653 321.602 224.01 322.123 223.279C322.204 223.165 322.351 223.395 322.428 223.279C322.536 223.115 322.569 222.605 322.659 222.447C322.919 221.99 323.373 221.716 323.33 221.516C323.194 220.887 322.527 220.258 321.675 220.258C320.823 220.258 320.002 221.185 319.61 221.185C319.217 221.185 318.759 221.863 318.998 222.882C319.238 223.901 319.137 224.088 319.287 224.332Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M321.86 223.839C322.124 223.839 322.338 223.625 322.338 223.36C322.338 223.096 322.124 222.882 321.86 222.882C321.596 222.882 321.383 223.096 321.383 223.36C321.383 223.625 321.596 223.839 321.86 223.839Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M316.676 235.644C316.676 235.644 318.949 236.163 320.289 235.996C321.628 235.829 322.914 234.866 322.914 234.866L325.088 245.544C325.289 246.531 325.328 247.543 325.205 248.542L324.116 257.346C324.071 257.706 323.766 257.975 323.404 257.975C323.044 257.975 322.74 257.709 322.696 257.351C322.56 256.23 322.293 253.88 322.293 252.691C322.293 252.234 322.332 251.686 322.389 251.138C322.573 249.342 322.399 247.514 321.693 245.853L321.452 245.284L319.784 254.225C319.704 254.649 319.334 254.957 318.901 254.957C318.406 254.957 318.004 254.556 318.004 254.06V244.648L316.676 235.644Z" fill="url(#paint15_linear)" />
      <mask id="mask15" mask-type="alpha" maskUnits="userSpaceOnUse" x="316" y="234" width="10" height="24">
        <path fillRule="evenodd" clipRule="evenodd" d="M316.676 235.644C316.676 235.644 318.949 236.163 320.289 235.996C321.628 235.829 322.914 234.866 322.914 234.866L325.088 245.544C325.289 246.531 325.328 247.543 325.205 248.542L324.116 257.346C324.071 257.706 323.766 257.975 323.404 257.975C323.044 257.975 322.74 257.709 322.696 257.351C322.56 256.23 322.293 253.88 322.293 252.691C322.293 252.234 322.332 251.686 322.389 251.138C322.573 249.342 322.399 247.514 321.693 245.853L321.452 245.284L319.784 254.225C319.704 254.649 319.334 254.957 318.901 254.957C318.406 254.957 318.004 254.556 318.004 254.06V244.648L316.676 235.644Z" fill="white" />
      </mask>
      <g mask="url(#mask15)">
        <path fillRule="evenodd" clipRule="evenodd" d="M321.6 245.882C321.6 245.882 320.375 242.021 320.217 241.928C320.059 241.835 319.773 243.691 320.484 245.225C321.196 246.759 320.484 251.378 320.484 251.378L321.6 245.882Z" fill="#00629C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M322.674 233.223C322.674 233.223 322.689 239.998 323.649 242.382C324.609 244.765 324.939 248.202 324.774 252.357C324.609 256.512 327.554 252.357 327.554 252.357V238.255L322.674 233.223Z" fill="#00629C" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M315.926 236.687C315.926 236.687 315.713 229.605 316.972 227.609C317.725 226.416 318.691 225.678 319.88 225.873C320.678 226.004 322.508 226.011 323.113 226.742C324.175 228.025 325.012 232.397 326.382 232.657C329.5 233.249 329.822 233.283 329.688 234.18C329.554 235.077 325.322 234.717 325.083 234.745C324.164 234.852 323.005 232.014 323.005 232.014L323.032 236.393C323.032 236.393 320.313 237.379 319.08 237.379C317.846 237.379 315.926 236.687 315.926 236.687Z" fill="url(#paint16_linear)" />
      <mask id="mask16" mask-type="alpha" maskUnits="userSpaceOnUse" x="315" y="225" width="15" height="13">
        <path fillRule="evenodd" clipRule="evenodd" d="M315.926 236.687C315.926 236.687 315.713 229.605 316.972 227.609C317.725 226.416 318.691 225.678 319.88 225.873C320.678 226.004 322.508 226.011 323.113 226.742C324.175 228.025 325.012 232.397 326.382 232.657C329.5 233.249 329.822 233.283 329.688 234.18C329.554 235.077 325.322 234.717 325.083 234.745C324.164 234.852 323.005 232.014 323.005 232.014L323.032 236.393C323.032 236.393 320.313 237.379 319.08 237.379C317.846 237.379 315.926 236.687 315.926 236.687Z" fill="white" />
      </mask>
      <g mask="url(#mask16)">
        <path fillRule="evenodd" clipRule="evenodd" d="M322.102 229.719C322.102 229.719 320.984 230.821 321.55 231.365C322.116 231.909 320.984 232.851 321.55 233.768C322.116 234.685 323.786 236.985 323.786 236.985L323.019 232.069L322.102 229.719Z" fill="#EF6F28" />
      </g>
      <g filter="url(#filter15_f)">
        <path d="M394.035 202.768L501.62 264.709C503.709 265.912 503.724 268.921 501.647 270.145L393.467 333.88" stroke="#020D1C" strokeWidth="5.24674" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path d="M289 76.9186L317.743 60.1084C321.017 58.1932 325.078 58.226 328.321 60.1939L417 114" stroke="url(#paint17_linear)" strokeWidth="1.24674" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="4.04 6" />
      <g opacity="0.15" filter="url(#filter16_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M486.581 203.817L626.704 194.313L601.029 119.823L506.881 65.4316L444.238 81.0964L486.581 203.817Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M486.98 202.865L559.017 161.24L559.132 119.107L450.34 97.7724L378.152 97.1381V139.815L486.98 202.865Z" fill="url(#paint18_linear)" />
      <mask id="mask17" mask-type="alpha" maskUnits="userSpaceOnUse" x="378" y="97" width="182" height="106">
        <path fillRule="evenodd" clipRule="evenodd" d="M486.98 202.865L559.017 161.24L559.132 119.107L450.34 97.7724L378.152 97.1381V139.815L486.98 202.865Z" fill="white" />
      </mask>
      <g mask="url(#mask17)">
        <path fillRule="evenodd" clipRule="evenodd" d="M486.704 186.382V196.957L534.812 169.779V158.644L486.704 186.382Z" fill="url(#paint19_linear)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M486.704 186.382V192.518L492.177 193.886L534.812 169.779V158.644L486.704 186.382Z" fill="url(#paint20_linear)" />
        <mask id="mask18" mask-type="alpha" maskUnits="userSpaceOnUse" x="486" y="158" width="49" height="36">
          <path fillRule="evenodd" clipRule="evenodd" d="M486.704 186.382V192.518L492.177 193.886L534.812 169.779V158.644L486.704 186.382Z" fill="white" />
        </mask>
        <g mask="url(#mask18)">
          <path fillRule="evenodd" clipRule="evenodd" d="M556.606 167.607V182.187L508.498 155.009V139.869L556.606 167.607Z" fill="#0682C3" />
          <path fillRule="evenodd" clipRule="evenodd" d="M482.826 184.189V194.764L530.934 167.586V156.451L482.826 184.189Z" fill="#A4C9EC" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M486.704 170.363V180.938L534.812 153.76V142.625L486.704 170.363Z" fill="url(#paint21_linear)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M486.704 170.363V176.499L492.177 177.867L534.812 153.76V142.625L486.704 170.363Z" fill="url(#paint22_linear)" />
        <mask id="mask19" mask-type="alpha" maskUnits="userSpaceOnUse" x="486" y="142" width="49" height="36">
          <path fillRule="evenodd" clipRule="evenodd" d="M486.704 170.363V176.499L492.177 177.867L534.812 153.76V142.625L486.704 170.363Z" fill="white" />
        </mask>
        <g mask="url(#mask19)">
          <path fillRule="evenodd" clipRule="evenodd" d="M556.606 151.588V166.168L508.498 138.99V123.85L556.606 151.588Z" fill="#0682C3" />
          <path fillRule="evenodd" clipRule="evenodd" d="M482.826 168.17V178.745L530.934 151.567V140.431L482.826 168.17Z" fill="#A4C9EC" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M486.98 202.865V158.644L378.152 97.1381V139.815L486.98 202.865Z" fill="url(#paint23_linear)" />
      <mask id="mask20" mask-type="alpha" maskUnits="userSpaceOnUse" x="378" y="97" width="109" height="106">
        <path fillRule="evenodd" clipRule="evenodd" d="M486.98 202.865V158.644L378.152 97.1381V139.815L486.98 202.865Z" fill="white" />
      </mask>
      <g mask="url(#mask20)">
        <g opacity="0.224842" filter="url(#filter17_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M409.587 112.309L424.246 118.283L436.169 147.975L435.723 174.729L422.878 182.314L402.274 156.827L409.587 112.309Z" fill="#2979AC" />
        </g>
        <g opacity="0.224842" filter="url(#filter18_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M418.655 125.961L433.314 131.936L445.238 161.627L444.791 188.382L431.946 195.967L411.343 170.479L418.655 125.961Z" fill="#2979AC" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M487.103 186.382V196.957L438.994 169.779V158.644L487.103 186.382Z" fill="url(#paint24_linear)" />
        <mask id="mask21" mask-type="alpha" maskUnits="userSpaceOnUse" x="438" y="158" width="50" height="39">
          <path fillRule="evenodd" clipRule="evenodd" d="M487.103 186.382V196.957L438.994 169.779V158.644L487.103 186.382Z" fill="white" />
        </mask>
        <g mask="url(#mask21)">
          <path fillRule="evenodd" clipRule="evenodd" d="M417.2 167.607V182.187L465.308 155.009V139.869L417.2 167.607Z" fill="#0169B4" />
          <path fillRule="evenodd" clipRule="evenodd" d="M490.98 184.189V194.764L442.872 167.586V156.451L490.98 184.189Z" fill="#C3DFFA" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M487.103 170.363V180.938L438.994 153.76V142.625L487.103 170.363Z" fill="url(#paint25_linear)" />
        <mask id="mask22" mask-type="alpha" maskUnits="userSpaceOnUse" x="438" y="142" width="50" height="39">
          <path fillRule="evenodd" clipRule="evenodd" d="M487.103 170.363V180.938L438.994 153.76V142.625L487.103 170.363Z" fill="white" />
        </mask>
        <g mask="url(#mask22)">
          <path fillRule="evenodd" clipRule="evenodd" d="M417.2 151.588V166.168L465.308 138.99V123.85L417.2 151.588Z" fill="#0169B4" />
          <path fillRule="evenodd" clipRule="evenodd" d="M490.98 168.17V178.745L442.872 151.567V140.431L490.98 168.17Z" fill="#C3DFFA" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M432.839 155.545V166.12L384.731 138.942V127.807L432.839 155.545Z" fill="url(#paint26_linear)" />
        <mask id="mask23" mask-type="alpha" maskUnits="userSpaceOnUse" x="384" y="127" width="49" height="40">
          <path fillRule="evenodd" clipRule="evenodd" d="M432.839 155.545V166.12L384.731 138.942V127.807L432.839 155.545Z" fill="white" />
        </mask>
        <g mask="url(#mask23)">
          <path fillRule="evenodd" clipRule="evenodd" d="M362.937 136.77V151.35L411.045 124.172V109.032L362.937 136.77Z" fill="#0169B4" />
          <path fillRule="evenodd" clipRule="evenodd" d="M436.717 153.352V163.927L388.608 136.749V125.614L436.717 153.352Z" fill="#C3DFFA" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M432.839 139.526V150.101L384.731 122.923V111.788L432.839 139.526Z" fill="url(#paint27_linear)" />
        <mask id="mask24" mask-type="alpha" maskUnits="userSpaceOnUse" x="384" y="111" width="49" height="40">
          <path fillRule="evenodd" clipRule="evenodd" d="M432.839 139.526V150.101L384.731 122.923V111.788L432.839 139.526Z" fill="white" />
        </mask>
        <g mask="url(#mask24)">
          <path fillRule="evenodd" clipRule="evenodd" d="M362.937 120.751V135.331L411.045 108.153V93.0131L362.937 120.751Z" fill="#0169B4" />
          <path fillRule="evenodd" clipRule="evenodd" d="M436.717 137.333V147.908L388.608 120.73V109.595L436.717 137.333Z" fill="#C3DFFA" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M487.326 164.963L562.286 121.648V118.044L449.198 55.6043L374.08 95.6522V99.3539L487.326 164.963Z" fill="#0178BE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M487.326 164.963V157.149L374.08 95.6522V99.3539L487.326 164.963Z" fill="#97CEF3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M487.326 161.358L562.286 118.044L449.198 52L374.08 95.7496L487.326 161.358Z" fill="url(#paint28_linear)" />
      <mask id="mask25" mask-type="alpha" maskUnits="userSpaceOnUse" x="374" y="52" width="189" height="110">
        <path fillRule="evenodd" clipRule="evenodd" d="M487.326 161.358L562.286 118.044L449.198 52L374.08 95.7496L487.326 161.358Z" fill="white" />
      </mask>
      <g mask="url(#mask25)">
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M514.846 105.881L514.846 101.05L468.053 82.0359L439.852 90.2722V95.078L486.527 122.084L514.846 105.881Z" fill="#0178BE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M486.527 111.997L439.852 90.2722V95.078L486.527 122.084L486.527 111.997Z" fill="#74B6E0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M514.846 101.075L468.053 74.0264L439.852 90.2722L486.527 117.278L514.846 101.075Z" fill="url(#paint29_linear)" />
      <g opacity="0.290016" filter="url(#filter19_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M487.326 165.336L501.833 193.307L559.297 161.358V123.85L487.326 165.336Z" fill="#00478A" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M534.387 179.367C532.12 177.453 531.766 174.277 532.995 171.578L553.815 125.861C554.294 124.808 555.791 124.809 556.269 125.863L577.014 171.582C578.238 174.28 577.882 177.45 575.619 179.362C571.835 182.56 564.907 186.856 555 186.856C545.099 186.856 538.172 182.564 534.387 179.367Z" fill="url(#paint30_linear)" />
      <mask id="mask26" mask-type="alpha" maskUnits="userSpaceOnUse" x="532" y="125" width="46" height="62">
        <path fillRule="evenodd" clipRule="evenodd" d="M534.387 179.367C532.12 177.453 531.766 174.277 532.995 171.578L553.815 125.861C554.294 124.808 555.791 124.809 556.269 125.863L577.014 171.582C578.238 174.28 577.882 177.45 575.619 179.362C571.835 182.56 564.907 186.856 555 186.856C545.099 186.856 538.172 182.564 534.387 179.367Z" fill="white" />
      </mask>
      <g mask="url(#mask26)">
        <g filter="url(#filter20_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M537.662 166.927C544.86 166.927 550.696 159.458 550.696 150.246C550.696 141.033 544.86 133.565 537.662 133.565C530.463 133.565 524.628 141.033 524.628 150.246C524.628 159.458 530.463 166.927 537.662 166.927Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter21_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M571.353 162.478C578.551 162.478 584.387 155.01 584.387 145.798C584.387 136.585 578.551 129.117 571.353 129.117C564.154 129.117 558.319 136.585 558.319 145.798C558.319 155.01 564.154 162.478 571.353 162.478Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter22_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M577.488 192.432C584.686 192.432 590.522 184.964 590.522 175.751C590.522 166.539 584.686 159.071 577.488 159.071C570.289 159.071 564.454 166.539 564.454 175.751C564.454 184.964 570.289 192.432 577.488 192.432Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter23_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M537.662 156.369C537.662 156.369 555.197 165.877 575.574 164.178L576.014 149.123H545.667L537.662 156.369Z" fill="#C23A00" />
        </g>
        <g filter="url(#filter24_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M553.627 160.694C553.627 160.694 560.814 173.813 581.19 172.113L576.014 149.123C576.014 149.123 545.667 149.123 545.667 149.123C545.667 149.123 553.627 160.694 553.627 160.694Z" fill="#C23A00" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M538.13 156.239C536.548 154.705 536.345 152.354 537.258 150.349L553.846 113.925C554.325 112.872 555.822 112.873 556.3 113.927L572.828 150.352C573.737 152.356 573.532 154.703 571.953 156.235C569.012 159.088 563.324 163.217 555.04 163.217C546.759 163.217 541.072 159.092 538.13 156.239Z" fill="url(#paint31_linear)" />
      <mask id="mask27" mask-type="alpha" maskUnits="userSpaceOnUse" x="536" y="113" width="38" height="51">
        <path fillRule="evenodd" clipRule="evenodd" d="M538.13 156.239C536.548 154.705 536.345 152.354 537.258 150.349L553.846 113.925C554.325 112.872 555.822 112.873 556.3 113.927L572.828 150.352C573.737 152.356 573.532 154.703 571.953 156.235C569.012 159.088 563.324 163.217 555.04 163.217C546.759 163.217 541.072 159.092 538.13 156.239Z" fill="white" />
      </mask>
      <g mask="url(#mask27)">
        <g filter="url(#filter25_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M541.131 146.334C546.906 146.334 551.587 140.343 551.587 132.953C551.587 125.563 546.906 119.572 541.131 119.572C535.356 119.572 530.675 125.563 530.675 132.953C530.675 140.343 535.356 146.334 541.131 146.334Z" fill="#FFDFC8" />
        </g>
      </g>
      <g opacity="0.440267" filter="url(#filter26_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M565 199.543L642 204L567.242 180L565 199.543Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M557.735 194.563C555.908 193.027 555.621 190.471 556.616 188.302L573.554 151.362C573.938 150.522 575.131 150.523 575.515 151.363L592.392 188.305C593.382 190.473 593.094 193.025 591.27 194.559C588.199 197.14 582.564 200.62 574.5 200.62C566.441 200.62 560.807 197.144 557.735 194.563Z" fill="url(#paint32_linear)" />
      <mask id="mask28" mask-type="alpha" maskUnits="userSpaceOnUse" x="556" y="150" width="37" height="51">
        <path fillRule="evenodd" clipRule="evenodd" d="M557.735 194.563C555.908 193.027 555.621 190.471 556.616 188.302L573.554 151.362C573.938 150.522 575.131 150.523 575.515 151.363L592.392 188.305C593.382 190.473 593.094 193.025 591.27 194.559C588.199 197.14 582.564 200.62 574.5 200.62C566.441 200.62 560.807 197.144 557.735 194.563Z" fill="white" />
      </mask>
      <g mask="url(#mask28)">
        <g filter="url(#filter27_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M560.413 184.537C566.261 184.537 571.003 178.51 571.003 171.076C571.003 163.642 566.261 157.615 560.413 157.615C554.564 157.615 549.823 163.642 549.823 171.076C549.823 178.51 554.564 184.537 560.413 184.537Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter28_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M587.786 180.948C593.635 180.948 598.376 174.921 598.376 167.486C598.376 160.052 593.635 154.025 587.786 154.025C581.938 154.025 577.196 160.052 577.196 167.486C577.196 174.921 581.938 180.948 587.786 180.948Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter29_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M592.771 205.12C598.62 205.12 603.361 199.093 603.361 191.659C603.361 184.224 598.62 178.197 592.771 178.197C586.922 178.197 582.181 184.224 582.181 191.659C582.181 199.093 586.922 205.12 592.771 205.12Z" fill="#ED3A00" />
        </g>
      </g>
      <path d="M214.551 97L299.128 145.231C301.249 146.44 301.243 149.5 299.118 150.701L150 235" stroke="url(#paint33_linear)" strokeWidth="2.24674" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M182.551 176L271.94 226.975L118 314" stroke="url(#paint34_linear)" strokeWidth="1.24674" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="4.04" />
      <path d="M269 172.389L308.765 194.138C312.003 195.909 315.939 195.819 319.093 193.903L416 135" stroke="url(#paint35_linear)" strokeWidth="2.24674" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M158 251.178L193.854 230L358 325" stroke="url(#paint36_linear)" strokeWidth="1.24674" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="4.04" />
      <path d="M385.57 152L493.306 213.884C495.399 215.086 495.414 218.1 493.333 219.323L385 283" stroke="url(#paint37_linear)" strokeWidth="2.24674" strokeLinecap="round" strokeLinejoin="round" />
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M386.438 161C395.517 161 402.877 157.194 402.877 152.5C402.877 147.806 395.517 144 386.438 144C377.36 144 370 147.806 370 152.5C370 157.194 377.36 161 386.438 161Z" fill="#FEAD45" />
      <path fillRule="evenodd" clipRule="evenodd" d="M386.438 156.974C391.217 156.974 395.09 154.971 395.09 152.5C395.09 150.029 391.217 148.026 386.438 148.026C381.66 148.026 377.787 150.029 377.787 152.5C377.787 154.971 381.66 156.974 386.438 156.974Z" fill="#FEAD45" />
      <path fillRule="evenodd" clipRule="evenodd" d="M386.438 154.289C388.35 154.289 389.899 153.488 389.899 152.5C389.899 151.512 388.35 150.711 386.438 150.711C384.527 150.711 382.978 151.512 382.978 152.5C382.978 153.488 384.527 154.289 386.438 154.289Z" fill="#F9CEAC" />
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M560.438 273C569.517 273 576.877 269.194 576.877 264.5C576.877 259.806 569.517 256 560.438 256C551.36 256 544 259.806 544 264.5C544 269.194 551.36 273 560.438 273Z" fill="#FEAD45" />
      <path fillRule="evenodd" clipRule="evenodd" d="M560.438 268.974C565.217 268.974 569.09 266.971 569.09 264.5C569.09 262.029 565.217 260.026 560.438 260.026C555.66 260.026 551.787 262.029 551.787 264.5C551.787 266.971 555.66 268.974 560.438 268.974Z" fill="#FEAD45" />
      <path fillRule="evenodd" clipRule="evenodd" d="M560.438 266.289C562.35 266.289 563.899 265.488 563.899 264.5C563.899 263.512 562.35 262.711 560.438 262.711C558.527 262.711 556.978 263.512 556.978 264.5C556.978 265.488 558.527 266.289 560.438 266.289Z" fill="#F9CEAC" />
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M264.438 279C273.517 279 280.877 275.194 280.877 270.5C280.877 265.806 273.517 262 264.438 262C255.36 262 248 265.806 248 270.5C248 275.194 255.36 279 264.438 279Z" fill="#FEAD45" />
      <path fillRule="evenodd" clipRule="evenodd" d="M264.438 274.974C269.217 274.974 273.09 272.971 273.09 270.5C273.09 268.029 269.217 266.026 264.438 266.026C259.66 266.026 255.787 268.029 255.787 270.5C255.787 272.971 259.66 274.974 264.438 274.974Z" fill="#FEAD45" />
      <path fillRule="evenodd" clipRule="evenodd" d="M264.438 272.289C266.35 272.289 267.899 271.488 267.899 270.5C267.899 269.512 266.35 268.711 264.438 268.711C262.527 268.711 260.978 269.512 260.978 270.5C260.978 271.488 262.527 272.289 264.438 272.289Z" fill="#F9CEAC" />
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M268.438 179C277.517 179 284.877 175.194 284.877 170.5C284.877 165.806 277.517 162 268.438 162C259.36 162 252 165.806 252 170.5C252 175.194 259.36 179 268.438 179Z" fill="#FEAD45" />
      <path fillRule="evenodd" clipRule="evenodd" d="M268.438 174.974C273.217 174.974 277.09 172.971 277.09 170.5C277.09 168.029 273.217 166.026 268.438 166.026C263.66 166.026 259.787 168.029 259.787 170.5C259.787 172.971 263.66 174.974 268.438 174.974Z" fill="#FEAD45" />
      <path fillRule="evenodd" clipRule="evenodd" d="M268.438 172.289C270.35 172.289 271.899 171.488 271.899 170.5C271.899 169.512 270.35 168.711 268.438 168.711C266.527 168.711 264.978 169.512 264.978 170.5C264.978 171.488 266.527 172.289 268.438 172.289Z" fill="#F9CEAC" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.192 205.824C10.0395 203.92 9.70927 200.855 10.8633 198.223L31.098 152.079C31.57 151.002 33.0973 151.003 33.5679 152.08L53.7301 198.227C54.879 200.857 54.5473 203.918 52.3983 205.819C48.7471 209.05 41.9902 213.456 32.2924 213.456C22.5994 213.456 15.8444 209.054 12.192 205.824Z" fill="url(#paint38_linear)" />
      <mask id="mask29" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="151" width="45" height="63">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.192 205.824C10.0395 203.92 9.70927 200.855 10.8633 198.223L31.098 152.079C31.57 151.002 33.0973 151.003 33.5679 152.08L53.7301 198.227C54.879 200.857 54.5473 203.918 52.3983 205.819C48.7471 209.05 41.9902 213.456 32.2924 213.456C22.5994 213.456 15.8444 209.054 12.192 205.824Z" fill="white" />
      </mask>
      <g mask="url(#mask29)">
        <g filter="url(#filter30_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M15.4653 193.369C22.4515 193.369 28.1149 185.842 28.1149 176.556C28.1149 167.271 22.4515 159.743 15.4653 159.743C8.47909 159.743 2.81567 167.271 2.81567 176.556C2.81567 185.842 8.47909 193.369 15.4653 193.369Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter31_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M48.1625 188.885C55.1487 188.885 60.8121 181.358 60.8121 172.073C60.8121 162.787 55.1487 155.26 48.1625 155.26C41.1764 155.26 35.5129 162.787 35.5129 172.073C35.5129 181.358 41.1764 188.885 48.1625 188.885Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter32_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M54.1169 219.076C61.1031 219.076 66.7665 211.549 66.7665 202.263C66.7665 192.978 61.1031 185.451 54.1169 185.451C47.1307 185.451 41.4673 192.978 41.4673 202.263C41.4673 211.549 47.1307 219.076 54.1169 219.076Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter33_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M8.99996 207.647C8.99996 207.647 27.952 160.661 30.8294 156.183C33.7067 151.705 22.0068 144.687 22.8442 142.642C23.6817 140.596 34.5863 145.803 34.5863 145.803H21.9176L0.566895 188.885L2.89345 203.917L8.99996 207.647Z" fill="white" />
        </g>
        <g filter="url(#filter34_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M15.4653 182.727C15.4653 182.727 32.4835 192.311 52.259 190.598L52.6867 175.425H23.2341L15.4653 182.727Z" fill="#C23A00" />
        </g>
        <g filter="url(#filter35_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M30.9595 187.087C30.9595 187.087 37.9346 200.309 57.7101 198.596L52.6867 175.425C52.6867 175.425 23.2341 175.425 23.2341 175.425C23.2341 175.425 30.9595 187.087 30.9595 187.087Z" fill="#C23A00" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8487 182.525C14.352 181.005 14.1628 178.737 15.0193 176.784L31.1279 140.049C31.5999 138.972 33.1272 138.973 33.5978 140.05L49.6485 176.787C50.5011 178.739 50.3109 181.003 48.8166 182.521C45.9834 185.398 40.4382 189.63 32.3307 189.63C24.2269 189.63 18.6831 185.402 15.8487 182.525Z" fill="url(#paint39_linear)" />
      <mask id="mask30" mask-type="alpha" maskUnits="userSpaceOnUse" x="14" y="139" width="37" height="51">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.8487 182.525C14.352 181.005 14.1628 178.737 15.0193 176.784L31.1279 140.049C31.5999 138.972 33.1272 138.973 33.5978 140.05L49.6485 176.787C50.5011 178.739 50.3109 181.003 48.8166 182.521C45.9834 185.398 40.4382 189.63 32.3307 189.63C24.2269 189.63 18.6831 185.402 15.8487 182.525Z" fill="white" />
      </mask>
      <g mask="url(#mask30)">
        <g filter="url(#filter36_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M18.8322 172.613C24.4365 172.613 28.9796 166.575 28.9796 159.126C28.9796 151.678 24.4365 145.639 18.8322 145.639C13.228 145.639 8.68481 151.678 8.68481 159.126C8.68481 166.575 13.228 172.613 18.8322 172.613Z" fill="#FFDFC8" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M43.8318 215.489C41.5352 213.762 41.2093 210.664 42.3633 208.033L54.542 180.26C55.0139 179.183 56.5413 179.184 57.0118 180.261L69.1472 208.037C70.2962 210.666 69.9689 213.76 67.676 215.485C65.1262 217.404 61.1293 219.473 55.751 219.473C50.3767 219.473 46.3818 217.407 43.8318 215.489Z" fill="url(#paint40_linear)" />
      <mask id="mask31" mask-type="alpha" maskUnits="userSpaceOnUse" x="41" y="179" width="29" height="41">
        <path fillRule="evenodd" clipRule="evenodd" d="M43.8318 215.489C41.5352 213.762 41.2093 210.664 42.3633 208.033L54.542 180.26C55.0139 179.183 56.5413 179.184 57.0118 180.261L69.1472 208.037C70.2962 210.666 69.9689 213.76 67.676 215.485C65.1262 217.404 61.1293 219.473 55.751 219.473C50.3767 219.473 46.3818 217.407 43.8318 215.489Z" fill="white" />
      </mask>
      <g mask="url(#mask31)">
        <g filter="url(#filter37_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M44.7332 206.321C49.3074 206.321 53.0156 201.393 53.0156 195.313C53.0156 189.233 49.3074 184.305 44.7332 184.305C40.1589 184.305 36.4507 189.233 36.4507 195.313C36.4507 201.393 40.1589 206.321 44.7332 206.321Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter38_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M66.1421 203.386C70.7164 203.386 74.4246 198.457 74.4246 192.377C74.4246 186.298 70.7164 181.369 66.1421 181.369C61.5678 181.369 57.8596 186.298 57.8596 192.377C57.8596 198.457 61.5678 203.386 66.1421 203.386Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter39_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M70.0408 223.153C74.6151 223.153 78.3233 218.225 78.3233 212.145C78.3233 206.065 74.6151 201.137 70.0408 201.137C65.4665 201.137 61.7583 206.065 61.7583 212.145C61.7583 218.225 65.4665 223.153 70.0408 223.153Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter40_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M40.4999 215.67C40.4999 215.67 52.909 184.905 54.793 181.973C56.677 179.041 49.0163 174.447 49.5646 173.107C50.1129 171.768 57.2529 175.177 57.2529 175.177H48.9579L34.9783 203.386L36.5016 213.228L40.4999 215.67Z" fill="white" />
        </g>
        <g filter="url(#filter41_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M44.7332 199.354C44.7332 199.354 55.876 205.629 68.8243 204.507L69.1043 194.572H49.8199L44.7332 199.354Z" fill="#C23A00" />
        </g>
        <g filter="url(#filter42_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M54.8784 202.208C54.8784 202.208 59.4453 210.865 72.3936 209.744L69.1045 194.572C69.1045 194.572 49.8201 194.572 49.8201 194.572C49.8201 194.572 54.8784 202.208 54.8784 202.208Z" fill="#C23A00" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M45.8417 200.031C44.2407 198.622 44.0588 196.334 44.9153 194.381L54.5614 172.383C55.0334 171.307 56.5607 171.308 57.0313 172.385L66.6429 194.384C67.4956 196.335 67.3126 198.62 65.7143 200.028C63.6957 201.805 60.3618 203.873 55.7759 203.873C51.1932 203.873 47.8608 201.808 45.8417 200.031Z" fill="url(#paint41_linear)" />
      <mask id="mask32" mask-type="alpha" maskUnits="userSpaceOnUse" x="44" y="171" width="24" height="33">
        <path fillRule="evenodd" clipRule="evenodd" d="M45.8417 200.031C44.2407 198.622 44.0588 196.334 44.9153 194.381L54.5614 172.383C55.0334 171.307 56.5607 171.308 57.0313 172.385L66.6429 194.384C67.4956 196.335 67.3126 198.62 65.7143 200.028C63.6957 201.805 60.3618 203.873 55.7759 203.873C51.1932 203.873 47.8608 201.808 45.8417 200.031Z" fill="white" />
      </mask>
      <g mask="url(#mask32)">
        <g filter="url(#filter43_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M46.9376 192.731C50.607 192.731 53.5817 188.778 53.5817 183.901C53.5817 179.024 50.607 175.07 46.9376 175.07C43.2681 175.07 40.2935 179.024 40.2935 183.901C40.2935 188.778 43.2681 192.731 46.9376 192.731Z" fill="#FFDFC8" />
        </g>
      </g>
      <g opacity="0.440267" filter="url(#filter44_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M300 403.543L377 408L302.242 384L300 403.543Z" fill="#0E0B0B" />
      </g>
      <g opacity="0.3" filter="url(#filter45_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M115.54 368.799L243.141 363.538L267.484 322.067L224.347 265.615L203.258 264.726L200.325 243.281L152.113 237.362L113.977 341.305L115.54 368.799Z" fill="#0E0B0B" />
      </g>
      <g opacity="0.178734" filter="url(#filter46_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4412 304.008L126.418 370.587L224.347 315.337L222.286 286.51L12.4412 281.64L12.4412 304.008Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 303.166L0 236.064L95.1243 184.027L152.456 214.158V281.26L57.5697 336.023L0 303.166Z" fill="#B5D6F3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 303.166L0 236.064L57.5697 268.988L152.456 214.158V281.26L57.5697 336.023L0 303.166Z" fill="#D4EDFF" />
      <mask id="mask33" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="214" width="153" height="123">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 303.166L0 236.064L57.5697 268.988L152.456 214.158V281.26L57.5697 336.023L0 303.166Z" fill="white" />
      </mask>
      <g mask="url(#mask33)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 303.97L0 236.868L57.1684 269.389V293.261H57.5697V336.827L0 303.97Z" fill="url(#paint42_linear)" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M57.5696 269.389L152.455 214.56V282.064L57.5696 336.827L57.5696 269.389Z" fill="url(#paint43_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 236.131L95.1243 181.281L152.456 214.225L57.5697 268.988L0 236.131Z" fill="url(#paint44_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M57.3896 335.942L57.3896 292.949L152.514 240.912L209.845 271.042V314.036L114.959 368.799L57.3896 335.942Z" fill="#9BD5FF" />
      <mask id="mask34" mask-type="alpha" maskUnits="userSpaceOnUse" x="57" y="240" width="153" height="129">
        <path fillRule="evenodd" clipRule="evenodd" d="M57.3896 335.942L57.3896 292.949L152.514 240.912L209.845 271.042V314.036L114.959 368.799L57.3896 335.942Z" fill="white" />
      </mask>
      <g mask="url(#mask34)">
        <path fillRule="evenodd" clipRule="evenodd" d="M57.3896 336.344L57.3896 293.351L114.959 326.274L209.845 271.444V314.438L114.959 369.201L57.3896 336.344Z" fill="url(#paint45_linear)" />
        <g filter="url(#filter47_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M72.5935 327.356V322.273L74.8243 323.598L77.6343 330.35L81.1306 332.426L77.9131 325.067C79.4575 325.62 81.0448 325.211 81.0448 322.68C81.0448 320.02 79.2216 316.985 76.24 315.215L69.5476 311.241V325.548L72.5935 327.356ZM75.7896 321.489L72.5935 319.592V315.731L75.7896 317.628C77.0122 318.354 77.9346 319.632 77.9346 320.833C77.9346 322.034 77.0122 322.215 75.7896 321.489ZM89.0886 331.918L85.485 335.012L81.8599 332.859L87.0508 328.606L82.1817 318.743L85.8067 320.896L89.0886 327.735L92.3275 324.768L95.9955 326.946L91.1263 331.004L96.3172 341.444L92.6707 339.279L89.0886 331.918Z" fill="#4094D0" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M69.2935 328.006V322.923L71.5243 324.248L74.3342 331L77.8306 333.076L74.6131 325.717C76.1575 326.27 77.7448 325.861 77.7448 323.33C77.7448 320.67 75.9215 317.635 72.94 315.865L66.2476 311.891V326.198L69.2935 328.006ZM72.4895 322.139L69.2935 320.242V316.381L72.4895 318.278C73.7122 319.004 74.6345 320.282 74.6345 321.483C74.6345 322.684 73.7122 322.865 72.4895 322.139ZM85.7885 332.568L82.1849 335.662L78.5599 333.509L83.7508 329.256L78.8816 319.393L82.5067 321.546L85.7885 328.385L89.0275 325.418L92.6954 327.596L87.8263 331.654L93.0172 342.094L89.3707 339.929L85.7885 332.568Z" fill="white" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M114.959 326.274L209.845 271.444V314.438L114.959 369.201L114.959 326.274Z" fill="url(#paint46_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M139.662 319.917V330.528L188.051 303.259V292.087L139.662 319.917Z" fill="#0074B1" />
      <mask id="mask35" mask-type="alpha" maskUnits="userSpaceOnUse" x="139" y="292" width="50" height="39">
        <path fillRule="evenodd" clipRule="evenodd" d="M139.662 319.917V330.528L188.051 303.259V292.087L139.662 319.917Z" fill="white" />
      </mask>
      <g mask="url(#mask35)">
        <path fillRule="evenodd" clipRule="evenodd" d="M209.972 301.08V315.709L161.583 288.44V273.25L209.972 301.08Z" fill="#0682C3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M135.762 317.717V328.327L184.151 301.059V289.886L135.762 317.717Z" fill="#7BBAE5" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M139.662 335.99V346.6L188.051 319.332V308.159L139.662 335.99Z" fill="#0074B1" />
      <mask id="mask36" mask-type="alpha" maskUnits="userSpaceOnUse" x="139" y="308" width="50" height="39">
        <path fillRule="evenodd" clipRule="evenodd" d="M139.662 335.99V346.6L188.051 319.332V308.159L139.662 335.99Z" fill="white" />
      </mask>
      <g mask="url(#mask36)">
        <g opacity="0.817825" filter="url(#filter48_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M139.662 335.99V346.6L188.051 319.332V308.159L139.662 335.99Z" fill="#F1A038" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M209.972 317.153V331.781L161.583 304.513V289.322L209.972 317.153Z" fill="#0682C3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M135.762 333.789V344.4L184.151 317.131V305.959L135.762 333.789Z" fill="#FFAE46" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.5737 279.312V289.922L4.18447 262.654V251.481L52.5737 279.312Z" fill="url(#paint47_linear)" />
      <mask id="mask37" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="251" width="49" height="39">
        <path fillRule="evenodd" clipRule="evenodd" d="M52.5737 279.312V289.922L4.18447 262.654V251.481L52.5737 279.312Z" fill="white" />
      </mask>
      <g mask="url(#mask37)">
        <path fillRule="evenodd" clipRule="evenodd" d="M-17.7371 260.475V275.103L30.6522 247.835V232.644L-17.7371 260.475Z" fill="#0169B4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M56.4736 277.111V287.722L8.08437 260.453V249.281L56.4736 277.111Z" fill="#D6EBFF" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.5737 295.384V305.995L4.18447 278.726V267.554L52.5737 295.384Z" fill="url(#paint48_linear)" />
      <mask id="mask38" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="267" width="49" height="39">
        <path fillRule="evenodd" clipRule="evenodd" d="M52.5737 295.384V305.995L4.18447 278.726V267.554L52.5737 295.384Z" fill="white" />
      </mask>
      <g mask="url(#mask38)">
        <path fillRule="evenodd" clipRule="evenodd" d="M-17.7371 276.547V291.176L30.6522 263.907V248.717L-17.7371 276.547Z" fill="#0169B4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M56.4736 293.184V303.794L8.08437 276.526V265.353L56.4736 293.184Z" fill="#D6EBFF" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M52.5737 311.457V322.067L4.18447 294.798V283.626L52.5737 311.457Z" fill="url(#paint49_linear)" />
      <mask id="mask39" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="283" width="49" height="40">
        <path fillRule="evenodd" clipRule="evenodd" d="M52.5737 311.457V322.067L4.18447 294.798V283.626L52.5737 311.457Z" fill="white" />
      </mask>
      <g mask="url(#mask39)">
        <path fillRule="evenodd" clipRule="evenodd" d="M-17.7371 292.619V307.248L30.6522 279.979V264.789L-17.7371 292.619Z" fill="#0169B4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M56.4736 309.256V319.866L8.08437 292.598V281.426L56.4736 309.256Z" fill="#D6EBFF" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M57.3896 293.016L152.514 238.166L209.845 271.109L114.959 325.872L57.3896 293.016Z" fill="url(#paint50_linear)" />
      <mask id="mask40" mask-type="alpha" maskUnits="userSpaceOnUse" x="57" y="238" width="153" height="88">
        <path fillRule="evenodd" clipRule="evenodd" d="M57.3896 293.016L152.514 238.166L209.845 271.109L114.959 325.872L57.3896 293.016Z" fill="white" />
      </mask>
      <g mask="url(#mask40)">
        <g opacity="0.290016" filter="url(#filter49_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M57.5903 291.934L93.4481 302.109L179.034 251.481L152.113 237.362L57.5903 291.934Z" fill="#00478A" />
        </g>
        <g opacity="0.15918" filter="url(#filter50_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M57.5903 291.934L81.4083 302.109L170.087 248.119L152.113 237.362L57.5903 291.934Z" fill="#00478A" />
        </g>
      </g>
      <g opacity="0.321777" filter="url(#filter51_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M62.4229 295.791L157.708 241.673L152.514 238.166V233.661L57.7357 287.722L57.5696 293.261L62.4229 295.791Z" fill="#005E93" />
      </g>
      <g opacity="0.440267" filter="url(#filter52_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M196.668 345.466L274.668 350.047L198.939 325.375L196.668 345.466Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M215.778 336.283C218.096 334.474 218.441 331.313 217.232 328.632L202.003 294.841C201.526 293.781 200.021 293.782 199.545 294.842L184.37 328.636C183.167 331.315 183.513 334.471 185.827 336.278C188.856 338.643 193.881 341.412 200.806 341.412C207.726 341.412 212.748 338.647 215.778 336.283Z" fill="url(#paint51_linear)" />
      <mask id="mask41" mask-type="alpha" maskUnits="userSpaceOnUse" x="183" y="294" width="35" height="48">
        <path fillRule="evenodd" clipRule="evenodd" d="M215.778 336.283C218.096 334.474 218.441 331.313 217.232 328.632L202.003 294.841C201.526 293.781 200.021 293.782 199.545 294.842L184.37 328.636C183.167 331.315 183.513 334.471 185.827 336.278C188.856 338.643 193.881 341.412 200.806 341.412C207.726 341.412 212.748 338.647 215.778 336.283Z" fill="white" />
      </mask>
      <g mask="url(#mask41)">
        <g filter="url(#filter53_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M214.087 325.985C208.573 325.985 204.103 320.204 204.103 313.073C204.103 305.942 208.573 300.161 214.087 300.161C219.602 300.161 224.072 305.942 224.072 313.073C224.072 320.204 219.602 325.985 214.087 325.985Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter54_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M188.279 322.542C182.765 322.542 178.295 316.761 178.295 309.63C178.295 302.499 182.765 296.718 188.279 296.718C193.794 296.718 198.264 302.499 198.264 309.63C198.264 316.761 193.794 322.542 188.279 322.542Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter55_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M214.087 317.813C214.087 317.813 200.655 325.173 185.046 323.858L184.708 312.204H207.955L214.087 317.813Z" fill="#C23A00" />
        </g>
        <g filter="url(#filter56_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M201.858 321.161C201.858 321.161 196.352 331.315 180.743 330L184.708 312.204C184.708 312.204 207.955 312.204 207.955 312.204C207.955 312.204 201.858 321.161 201.858 321.161Z" fill="#C23A00" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M213.164 318.244C214.783 316.779 214.977 314.441 214.08 312.45L201.98 285.602C201.502 284.542 199.997 284.543 199.521 285.603L187.465 312.453C186.571 314.443 186.767 316.777 188.383 318.241C190.762 320.396 194.923 323.114 200.776 323.114C206.625 323.114 210.784 320.399 213.164 318.244Z" fill="url(#paint52_linear)" />
      <mask id="mask42" mask-type="alpha" maskUnits="userSpaceOnUse" x="186" y="284" width="29" height="40">
        <path fillRule="evenodd" clipRule="evenodd" d="M213.164 318.244C214.783 316.779 214.977 314.441 214.08 312.45L201.98 285.602C201.502 284.542 199.997 284.543 199.521 285.603L187.465 312.453C186.571 314.443 186.767 316.777 188.383 318.241C190.762 320.396 194.923 323.114 200.776 323.114C206.625 323.114 210.784 320.399 213.164 318.244Z" fill="white" />
      </mask>
      <g mask="url(#mask42)">
      </g>
      <g opacity="0.440267" filter="url(#filter57_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M187.547 357.794L265.548 362.375L189.818 337.703L187.547 357.794Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M199.614 352.029C201.847 350.116 202.194 346.971 200.986 344.29L180.188 298.143C179.711 297.084 178.206 297.085 177.73 298.145L157.007 344.295C155.804 346.974 156.152 350.113 158.382 352.024C162.146 355.252 169.075 359.62 179.001 359.62C188.921 359.62 195.848 355.256 199.614 352.029Z" fill="url(#paint53_linear)" />
      <mask id="mask43" mask-type="alpha" maskUnits="userSpaceOnUse" x="156" y="297" width="46" height="63">
        <path fillRule="evenodd" clipRule="evenodd" d="M199.614 352.029C201.847 350.116 202.194 346.971 200.986 344.29L180.188 298.143C179.711 297.084 178.206 297.085 177.73 298.145L157.007 344.295C155.804 346.974 156.152 350.113 158.382 352.024C162.146 355.252 169.075 359.62 179.001 359.62C188.921 359.62 195.848 355.256 199.614 352.029Z" fill="white" />
      </mask>
      <g mask="url(#mask43)">
        <g filter="url(#filter58_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M196.298 339.53C189.116 339.53 183.295 332.001 183.295 322.714C183.295 313.427 189.116 305.899 196.298 305.899C203.479 305.899 209.301 313.427 209.301 322.714C209.301 332.001 203.479 339.53 196.298 339.53Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter59_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M162.687 335.046C155.506 335.046 149.684 327.517 149.684 318.23C149.684 308.943 155.506 301.414 162.687 301.414C169.869 301.414 175.69 308.943 175.69 318.23C175.69 327.517 169.869 335.046 162.687 335.046Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter60_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M196.298 328.887C196.298 328.887 178.804 338.472 158.476 336.759L158.037 321.583H188.312L196.298 328.887Z" fill="#C23A00" />
        </g>
        <g filter="url(#filter61_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M180.371 333.246C180.371 333.246 173.201 346.471 152.873 344.758L158.037 321.583C158.037 321.583 188.312 321.583 188.312 321.583C188.312 321.583 180.371 333.246 180.371 333.246Z" fill="#C23A00" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M195.865 328.722C197.422 327.191 197.622 324.862 196.724 322.871L180.158 286.111C179.68 285.052 178.175 285.053 177.699 286.113L161.192 322.874C160.298 324.864 160.499 327.189 162.054 328.718C164.978 331.595 170.664 335.79 178.961 335.79C187.255 335.79 192.94 331.599 195.865 328.722Z" fill="url(#paint54_linear)" />
      <mask id="mask44" mask-type="alpha" maskUnits="userSpaceOnUse" x="160" y="285" width="38" height="51">
        <path fillRule="evenodd" clipRule="evenodd" d="M195.865 328.722C197.422 327.191 197.622 324.862 196.724 322.871L180.158 286.111C179.68 285.052 178.175 285.053 177.699 286.113L161.192 322.874C160.298 324.864 160.499 327.189 162.054 328.718C164.978 331.595 170.664 335.79 178.961 335.79C187.255 335.79 192.94 331.599 195.865 328.722Z" fill="white" />
      </mask>
      <g mask="url(#mask44)">
      </g>
      <g opacity="0.3" filter="url(#filter62_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M399.146 461.817L549.657 461.391L594.961 396.986L545.625 349.296L438.178 341.557L399.146 369.174V461.817Z" fill="#0E0B0B" />
      </g>
      <g opacity="0.440267" filter="url(#filter63_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M287.65 374.202L408.24 445.102L521.402 379.258L521.939 350.795L287.65 356.832V374.202Z" fill="#0E0B0B" />
      </g>
      <g opacity="0.22454" filter="url(#filter64_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M282.863 379.002L404.809 458.917H435.307L531.032 397.359L517.094 393.723L517.152 355.595L282.863 361.632V379.002Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M278.541 392.479L278.541 279.041L364.361 232.017L416.084 259.245V372.683L330.48 422.171L278.541 392.479Z" fill="#D1EAFF" />
      <mask id="mask45" mask-type="alpha" maskUnits="userSpaceOnUse" x="278" y="232" width="139" height="191">
        <path fillRule="evenodd" clipRule="evenodd" d="M278.541 392.479L278.541 279.041L364.361 232.017L416.084 259.245V372.683L330.48 422.171L278.541 392.479Z" fill="white" />
      </mask>
      <g mask="url(#mask45)">
        <path fillRule="evenodd" clipRule="evenodd" d="M278.541 392.959L278.541 279.521L330.48 309.273L416.084 259.725V373.163L330.48 422.651L278.541 392.959Z" fill="url(#paint55_linear)" />
        <mask id="mask46" mask-type="alpha" maskUnits="userSpaceOnUse" x="278" y="259" width="139" height="164">
          <path fillRule="evenodd" clipRule="evenodd" d="M278.541 392.959L278.541 279.521L330.48 309.273L416.084 259.725V373.163L330.48 422.651L278.541 392.959Z" fill="white" />
        </mask>
        <g mask="url(#mask46)">
          <g opacity="0.224842" filter="url(#filter65_f)">
            <path fillRule="evenodd" clipRule="evenodd" d="M274.231 328.564L291.815 335.725L306.119 371.312L305.584 403.379L290.175 412.47L265.459 381.922L274.231 328.564Z" fill="#2979AC" />
          </g>
          <g opacity="0.224842" filter="url(#filter66_f)">
            <path fillRule="evenodd" clipRule="evenodd" d="M288.261 363.2L305.845 370.361L320.148 405.948L319.613 438.015L304.205 447.106L279.489 416.558L288.261 363.2Z" fill="#2979AC" />
          </g>
          <g filter="url(#filter67_f)">
            <path fillRule="evenodd" clipRule="evenodd" d="M279.489 335.86C291.648 335.86 301.506 320.608 301.506 301.794C301.506 282.979 291.648 267.727 279.489 267.727C267.329 267.727 257.472 282.979 257.472 301.794C257.472 320.608 267.329 335.86 279.489 335.86Z" fill="#EAFDFF" />
          </g>
          <g filter="url(#filter68_f)">
            <path fillRule="evenodd" clipRule="evenodd" d="M296.512 336.917V339.355L287.332 334.088V320.551L290.372 322.296V333.394L296.512 336.917ZM301.073 338.977L299.767 341.223L296.707 339.467L303.022 329.553L305.458 330.951L311.773 348.111L308.772 346.389L307.466 342.644L301.073 338.977ZM302.067 337.281L304.269 333.476L306.472 339.808L302.067 337.281ZM322.971 353.537C322.12 353.715 320.947 353.375 319.453 352.517L312.923 348.771V335.234L319.258 338.868C320.687 339.688 321.821 340.652 322.659 341.761C323.497 342.869 323.916 343.967 323.916 345.055C323.916 345.759 323.724 346.267 323.341 346.578C322.958 346.889 322.428 346.973 321.752 346.828C322.545 347.513 323.159 348.266 323.594 349.085C324.03 349.904 324.247 350.73 324.247 351.562C324.247 352.702 323.822 353.36 322.971 353.537ZM315.886 342.445V339.219L318.751 340.863C319.531 341.31 320.106 341.771 320.476 342.246C320.846 342.721 321.031 343.24 321.031 343.803C321.031 344.341 320.846 344.634 320.476 344.684C320.106 344.734 319.531 344.536 318.751 344.088L315.886 342.445ZM315.886 344.73V348.186L319.102 350.031C319.907 350.493 320.492 350.694 320.856 350.634C321.22 350.574 321.402 350.243 321.402 349.642C321.402 349.04 321.22 348.494 320.856 348.004C320.492 347.513 319.907 347.037 319.102 346.575L315.886 344.73Z" fill="#4094D0" />
          </g>
          <path fillRule="evenodd" clipRule="evenodd" d="M293.875 338.238V340.677L284.695 335.41V321.872L287.736 323.617V334.715L293.875 338.238ZM298.436 340.298L297.13 342.544L294.07 340.788L300.385 330.874L302.821 332.272L309.136 349.433L306.135 347.71L304.829 343.966L298.436 340.298ZM299.43 338.602L301.633 334.797L303.835 341.13L299.43 338.602ZM320.334 354.858C319.483 355.036 318.31 354.696 316.816 353.838L310.286 350.092V336.555L316.621 340.19C318.05 341.01 319.184 341.974 320.022 343.082C320.86 344.19 321.279 345.288 321.279 346.376C321.279 347.08 321.088 347.588 320.704 347.899C320.321 348.21 319.791 348.294 319.116 348.149C319.908 348.835 320.522 349.587 320.958 350.406C321.393 351.226 321.611 352.051 321.611 352.883C321.611 354.023 321.185 354.681 320.334 354.858ZM313.249 343.766V340.54L316.114 342.184C316.894 342.631 317.469 343.092 317.839 343.567C318.209 344.042 318.395 344.561 318.395 345.124C318.395 345.662 318.209 345.956 317.839 346.006C317.469 346.056 316.894 345.857 316.114 345.41L313.249 343.766ZM313.249 346.051V349.507L316.465 351.352C317.271 351.814 317.855 352.015 318.219 351.955C318.583 351.895 318.765 351.564 318.765 350.963C318.765 350.361 318.583 349.815 318.219 349.325C317.855 348.834 317.271 348.358 316.465 347.896L313.249 346.051Z" fill="white" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M330.479 309.273L416.084 259.725V373.163L330.479 342.491V309.273Z" fill="url(#paint56_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M278.541 279.102L364.361 229.535L416.084 259.305L330.48 308.793L278.541 279.102Z" fill="url(#paint57_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M289.819 278.428L363.282 236.081L403.359 259.245L330.71 301.804L289.819 278.428Z" fill="url(#paint58_linear)" />
      <mask id="mask47" mask-type="alpha" maskUnits="userSpaceOnUse" x="289" y="236" width="115" height="66">
        <path fillRule="evenodd" clipRule="evenodd" d="M289.819 278.428L363.282 236.081L403.359 259.245L330.71 301.804L289.819 278.428Z" fill="white" />
      </mask>
      <g mask="url(#mask47)">
        <path fillRule="evenodd" clipRule="evenodd" d="M289.819 285.148L363.282 242.801L403.359 265.965L330.71 308.524L289.819 285.148Z" fill="#5EADE6" />
        <mask id="mask48" mask-type="alpha" maskUnits="userSpaceOnUse" x="289" y="242" width="115" height="67">
          <path fillRule="evenodd" clipRule="evenodd" d="M289.819 285.148L363.282 242.801L403.359 265.965L330.71 308.524L289.819 285.148Z" fill="white" />
        </mask>
        <g mask="url(#mask48)">
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M363.281 235.121V242.801L403.359 265.965V258.285L363.281 235.121Z" fill="url(#paint59_linear)" />
        <g opacity="0.6" filter="url(#filter69_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M359.42 235.74L406.711 263.279L399.974 267.727L355.376 241.12L359.42 238.428V235.74Z" fill="#0169B4" />
        </g>
        <g opacity="0.6" filter="url(#filter70_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M361.504 239.82L287.508 281.232L294.245 285.68L365.549 245.2L361.504 242.508V239.82Z" fill="#0169B4" />
        </g>
        <g opacity="0.15" filter="url(#filter71_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M289.34 277.948L362.803 235.602L390.587 258.765L322.915 298.336L289.34 277.948Z" fill="#00478A" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M330.487 422.141L330.487 341.982L443.934 279.82L512.308 315.813V395.972L399.146 461.391L330.487 422.141Z" fill="url(#paint60_linear)" />
      <mask id="mask49" mask-type="alpha" maskUnits="userSpaceOnUse" x="330" y="279" width="183" height="183">
        <path fillRule="evenodd" clipRule="evenodd" d="M330.487 422.141L330.487 341.982L443.934 279.82L512.308 315.813V395.972L399.146 461.391L330.487 422.141Z" fill="white" />
      </mask>
      <g mask="url(#mask49)">
        <path fillRule="evenodd" clipRule="evenodd" d="M330.487 422.621L330.487 342.462L399.146 381.792L512.308 316.293V396.452L399.146 461.871L330.487 422.621Z" fill="url(#paint61_linear)" />
        <mask id="mask50" mask-type="alpha" maskUnits="userSpaceOnUse" x="330" y="316" width="183" height="146">
          <path fillRule="evenodd" clipRule="evenodd" d="M330.487 422.621L330.487 342.462L399.146 381.792L512.308 316.293V396.452L399.146 461.871L330.487 422.621Z" fill="white" />
        </mask>
        <g mask="url(#mask50)">
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M393.187 393.165V444.239L335.477 411.665V359.919L393.187 393.165Z" fill="#5EADE6" />
      <mask id="mask51" mask-type="alpha" maskUnits="userSpaceOnUse" x="335" y="359" width="59" height="86">
        <path fillRule="evenodd" clipRule="evenodd" d="M393.187 393.165V444.239L335.477 411.665V359.919L393.187 393.165Z" fill="white" />
      </mask>
      <g mask="url(#mask51)">
        <path fillRule="evenodd" clipRule="evenodd" d="M309.333 371.142V427.017L367.043 394.443V337.897L309.333 371.142Z" fill="url(#paint62_linear)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M416.984 380.456V431.531L359.274 398.957V347.211L416.984 380.456Z" fill="url(#paint63_linear)" />
        <g opacity="0.720331" filter="url(#filter72_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M335.273 405.035L356.271 393.137V371.802L362.906 375.563V394.763L393.177 412.056V422.171L359.274 403.258L340.442 415.094L335.477 411.665L335.273 405.035Z" fill="#0169B4" />
        </g>
        <g opacity="0.290016" filter="url(#filter73_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M335.477 411.665L336.25 411.882L353.334 422.171L376.909 408.724V382.457L359.274 373.258L335.477 359.919" fill="#00478A" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M399.146 381.792L512.308 316.293V396.452L399.146 461.871L399.146 381.792Z" fill="url(#paint64_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M430.521 385.848V398.523L488.23 365.949V352.603L430.521 385.848Z" fill="#0074B1" />
      <mask id="mask52" mask-type="alpha" maskUnits="userSpaceOnUse" x="430" y="352" width="59" height="47">
        <path fillRule="evenodd" clipRule="evenodd" d="M430.521 385.848V398.523L488.23 365.949V352.603L430.521 385.848Z" fill="white" />
      </mask>
      <g mask="url(#mask52)">
        <g opacity="0.817825" filter="url(#filter74_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M430.521 385.848V398.523L488.23 365.949V352.603L430.521 385.848Z" fill="#F1A038" />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M514.374 363.346V380.821L456.664 348.246V330.1L514.374 363.346Z" fill="#0682C3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M425.869 383.219V395.895L483.579 363.32V349.974L425.869 383.219Z" fill="#FFAE46" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M430.521 405.048V417.723L488.23 385.149V371.802L430.521 405.048Z" fill="#0074B1" />
      <mask id="mask53" mask-type="alpha" maskUnits="userSpaceOnUse" x="430" y="371" width="59" height="47">
        <path fillRule="evenodd" clipRule="evenodd" d="M430.521 405.048V417.723L488.23 385.149V371.802L430.521 405.048Z" fill="white" />
      </mask>
      <g mask="url(#mask53)">
        <path fillRule="evenodd" clipRule="evenodd" d="M514.374 382.545V400.02L456.664 367.446V349.3L514.374 382.545Z" fill="#0682C3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M425.869 402.419V415.094L483.579 382.52V369.174L425.869 402.419Z" fill="#7BBAE5" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M330.487 342.062L443.934 276.539L512.308 315.893L399.146 381.312L330.487 342.062Z" fill="url(#paint65_linear)" />
      <mask id="mask54" mask-type="alpha" maskUnits="userSpaceOnUse" x="330" y="276" width="183" height="106">
        <path fillRule="evenodd" clipRule="evenodd" d="M330.487 342.062L443.934 276.539L512.308 315.893L399.146 381.312L330.487 342.062Z" fill="white" />
      </mask>
      <g mask="url(#mask54)">
        <g opacity="0.290016" filter="url(#filter75_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M382.167 350.939L395.341 356.302L451.008 324.117L438.178 318.779L382.167 350.939Z" fill="#00478A" />
        </g>
        <g opacity="0.1" filter="url(#filter76_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M382.167 350.939L395.341 351.982L451.008 319.797L438.178 318.779L382.167 350.939Z" fill="#00478A" />
        </g>
        <g opacity="0.307408" filter="url(#filter77_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M382.167 350.939L387.683 352.942L443.35 320.757L438.178 318.779L382.167 350.939Z" fill="#0169B4" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M348.196 331.999L348.196 326.208L404.328 303.419L438.158 313.291V319.051L382.167 351.419L348.196 331.999Z" fill="#3A9BD3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M348.196 331.999L348.196 326.208L382.167 335.311V351.419L348.196 331.999Z" fill="#1286D0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M348.196 326.239L404.328 293.819L438.158 313.291L382.167 345.659L348.196 326.239Z" fill="url(#paint66_linear)" />
      <g opacity="0.290016" filter="url(#filter78_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M416.376 291.099L443.518 299.785L429.566 307.564L424.553 304.404L370.654 337.373V343.354L356.627 351.643L330.487 341.153L348.196 330.818V325.054L404.328 292.859L409.292 295.72L416.376 291.099Z" fill="#00478A" />
      </g>
      <g opacity="0.440267" filter="url(#filter79_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M479.839 415.962L557.359 420.523L482.096 395.962L479.839 415.962Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M522.351 428.632C524.58 426.721 524.927 423.581 523.722 420.903L503.067 374.996C502.589 373.935 501.083 373.936 500.607 374.997L480.026 420.907C478.826 423.583 479.174 426.718 481.4 428.627C485.145 431.839 492.026 436.177 501.878 436.177C511.726 436.177 518.605 431.844 522.351 428.632Z" fill="url(#paint67_linear)" />
      <mask id="mask55" mask-type="alpha" maskUnits="userSpaceOnUse" x="479" y="374" width="46" height="63">
        <path fillRule="evenodd" clipRule="evenodd" d="M522.351 428.632C524.58 426.721 524.927 423.581 523.722 420.903L503.067 374.996C502.589 373.935 501.083 373.936 500.607 374.997L480.026 420.907C478.826 423.583 479.174 426.718 481.4 428.627C485.145 431.839 492.026 436.177 501.878 436.177C511.726 436.177 518.605 431.844 522.351 428.632Z" fill="white" />
      </mask>
      <g mask="url(#mask55)">
        <g filter="url(#filter80_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M519.069 416.178C511.932 416.178 506.146 408.683 506.146 399.438C506.146 390.193 511.932 382.698 519.069 382.698C526.206 382.698 531.992 390.193 531.992 399.438C531.992 408.683 526.206 416.178 519.069 416.178Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter81_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M485.665 411.714C478.528 411.714 472.743 404.219 472.743 394.974C472.743 385.729 478.528 378.234 485.665 378.234C492.803 378.234 498.588 385.729 498.588 394.974C498.588 404.219 492.803 411.714 485.665 411.714Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter82_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M519.069 405.582C519.069 405.582 501.683 415.125 481.481 413.419L481.044 398.312H511.132L519.069 405.582Z" fill="#C23A00" />
        </g>
        <g filter="url(#filter83_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M503.24 409.923C503.24 409.923 496.114 423.088 475.912 421.382L481.043 398.312C481.043 398.312 511.132 398.312 511.132 398.312C511.132 398.312 503.24 409.923 503.24 409.923Z" fill="#C23A00" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M518.63 405.428C520.184 403.898 520.383 401.573 519.489 399.584L503.036 363.018C502.559 361.957 501.053 361.958 500.577 363.02L484.184 399.587C483.293 401.575 483.493 403.896 485.045 405.424C487.954 408.288 493.602 412.455 501.839 412.455C510.073 412.455 515.72 408.291 518.63 405.428Z" fill="url(#paint68_linear)" />
      <mask id="mask56" mask-type="alpha" maskUnits="userSpaceOnUse" x="483" y="362" width="38" height="51">
        <path fillRule="evenodd" clipRule="evenodd" d="M518.63 405.428C520.184 403.898 520.383 401.573 519.489 399.584L503.036 363.018C502.559 361.957 501.053 361.958 500.577 363.02L484.184 399.587C483.293 401.575 483.493 403.896 485.045 405.424C487.954 408.288 493.602 412.455 501.839 412.455C510.073 412.455 515.72 408.291 518.63 405.428Z" fill="white" />
      </mask>
      <g mask="url(#mask56)">
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M248.323 396.36C246.094 394.448 245.747 391.308 246.952 388.63L267.607 342.723C268.085 341.663 269.591 341.664 270.067 342.725L290.648 388.635C291.848 391.311 291.5 394.446 289.274 396.355C285.53 399.567 278.648 403.905 268.796 403.905C258.948 403.905 252.069 399.571 248.323 396.36Z" fill="url(#paint69_linear)" />
      <mask id="mask57" mask-type="alpha" maskUnits="userSpaceOnUse" x="246" y="341" width="46" height="63">
        <path fillRule="evenodd" clipRule="evenodd" d="M248.323 396.36C246.094 394.448 245.747 391.308 246.952 388.63L267.607 342.723C268.085 341.663 269.591 341.664 270.067 342.725L290.648 388.635C291.848 391.311 291.5 394.446 289.274 396.355C285.53 399.567 278.648 403.905 268.796 403.905C258.948 403.905 252.069 399.571 248.323 396.36Z" fill="white" />
      </mask>
      <g mask="url(#mask57)">
        <g filter="url(#filter84_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M251.605 383.905C258.742 383.905 264.528 376.411 264.528 367.166C264.528 357.92 258.742 350.426 251.605 350.426C244.468 350.426 238.682 357.92 238.682 367.166C238.682 376.411 244.468 383.905 251.605 383.905Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter85_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M285.009 379.441C292.146 379.441 297.931 371.947 297.931 362.702C297.931 353.457 292.146 345.962 285.009 345.962C277.871 345.962 272.086 353.457 272.086 362.702C272.086 371.947 277.871 379.441 285.009 379.441Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter86_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M291.092 409.501C298.229 409.501 304.014 402.006 304.014 392.761C304.014 383.516 298.229 376.021 291.092 376.021C283.954 376.021 278.169 383.516 278.169 392.761C278.169 402.006 283.954 409.501 291.092 409.501Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter87_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M251.605 373.31C251.605 373.31 268.991 382.852 289.194 381.147L289.631 366.039H259.542L251.605 373.31Z" fill="#C23A00" />
        </g>
        <g filter="url(#filter88_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M267.434 377.65C267.434 377.65 274.56 390.815 294.763 389.11L289.631 366.039C289.631 366.039 259.542 366.039 259.542 366.039C259.542 366.039 267.434 377.65 267.434 377.65Z" fill="#C23A00" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M252.045 373.155C250.49 371.626 250.291 369.3 251.185 367.312L267.638 330.746C268.115 329.685 269.622 329.686 270.097 330.747L286.491 367.315C287.381 369.302 287.181 371.624 285.629 373.151C282.72 376.015 277.072 380.183 268.835 380.183C260.601 380.183 254.955 376.019 252.045 373.155Z" fill="url(#paint70_linear)" />
      <mask id="mask58" mask-type="alpha" maskUnits="userSpaceOnUse" x="250" y="329" width="38" height="52">
        <path fillRule="evenodd" clipRule="evenodd" d="M252.045 373.155C250.49 371.626 250.291 369.3 251.185 367.312L267.638 330.746C268.115 329.685 269.622 329.686 270.097 330.747L286.491 367.315C287.381 369.302 287.181 371.624 285.629 373.151C282.72 376.015 277.072 380.183 268.835 380.183C260.601 380.183 254.955 376.019 252.045 373.155Z" fill="white" />
      </mask>
      <g mask="url(#mask58)">
        <g filter="url(#filter89_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M255.045 363.24C260.77 363.24 265.411 357.228 265.411 349.811C265.411 342.395 260.77 336.383 255.045 336.383C249.319 336.383 244.678 342.395 244.678 349.811C244.678 357.228 249.319 363.24 255.045 363.24Z" fill="#FFDFC8" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M275.08 409.826C273.296 408.297 273.019 405.785 273.983 403.642L290.507 366.916C290.889 366.068 292.094 366.069 292.475 366.918L308.94 403.645C309.899 405.786 309.621 408.294 307.84 409.822C304.845 412.391 299.34 415.862 291.458 415.862C283.58 415.862 278.076 412.395 275.08 409.826Z" fill="url(#paint71_linear)" />
      <mask id="mask59" mask-type="alpha" maskUnits="userSpaceOnUse" x="273" y="366" width="37" height="50">
        <path fillRule="evenodd" clipRule="evenodd" d="M275.08 409.826C273.296 408.297 273.019 405.785 273.983 403.642L290.507 366.916C290.889 366.068 292.094 366.069 292.475 366.918L308.94 403.645C309.899 405.786 309.621 408.294 307.84 409.822C304.845 412.391 299.34 415.862 291.458 415.862C283.58 415.862 278.076 412.395 275.08 409.826Z" fill="white" />
      </mask>
      <g mask="url(#mask59)">
        <g filter="url(#filter90_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M277.705 399.862C283.415 399.862 288.044 393.866 288.044 386.47C288.044 379.074 283.415 373.078 277.705 373.078C271.996 373.078 267.367 379.074 267.367 386.47C267.367 393.866 271.996 399.862 277.705 399.862Z" fill="#FFDFC8" />
        </g>
        <g filter="url(#filter91_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M304.428 396.291C310.138 396.291 314.767 390.295 314.767 382.899C314.767 375.503 310.138 369.507 304.428 369.507C298.719 369.507 294.09 375.503 294.09 382.899C294.09 390.295 298.719 396.291 304.428 396.291Z" fill="#ED3A00" />
        </g>
        <g filter="url(#filter92_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M309.295 420.338C315.004 420.338 319.633 414.343 319.633 406.947C319.633 399.551 315.004 393.555 309.295 393.555C303.585 393.555 298.956 399.551 298.956 406.947C298.956 414.343 303.585 420.338 309.295 420.338Z" fill="#ED3A00" />
        </g>
      </g>
      <g opacity="0.327125" filter="url(#filter93_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M448.15 215.262C458.67 215.262 467.198 213.343 467.198 210.976C467.198 208.608 458.67 206.689 448.15 206.689C437.63 206.689 429.102 208.608 429.102 210.976C429.102 213.343 437.63 215.262 448.15 215.262Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M432.9 182.4C432.9 182.4 432.928 180.46 432.554 176.875C432.18 173.291 436.458 173.291 437.552 175.591C438.645 177.89 441.618 183.283 439.076 184.94C436.535 186.597 435.586 184.233 435.586 184.233L432.9 182.4Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M436.656 207.709C436.656 207.709 436.099 208.362 435.919 208.606C434.819 210.095 434.514 210.988 434.715 211.181C435.59 212.018 437.387 210.065 437.694 209.482C438.002 208.9 437.455 207.284 437.455 207.284L436.656 207.709Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M433.509 207.043C433.509 207.043 432.953 207.696 432.773 207.94C431.673 209.429 431.3 209.962 431.501 210.155C432.376 210.992 434.24 209.398 434.548 208.816C434.856 208.233 434.094 206.852 434.094 206.852L433.509 207.043Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M436.521 176.591C436.521 176.591 435.552 179.7 436.775 180.397C437.998 181.093 435.382 181.442 435.382 181.442L432.889 181.198L433.145 179.489C433.145 179.489 434.173 179.586 434.428 177.984C434.682 176.383 434.899 176.882 434.899 176.882L436.521 176.591Z" fill="#F49F95" />
      <mask id="mask60" mask-type="alpha" maskUnits="userSpaceOnUse" x="432" y="176" width="6" height="6">
        <path fillRule="evenodd" clipRule="evenodd" d="M436.521 176.591C436.521 176.591 435.552 179.7 436.775 180.397C437.998 181.093 435.382 181.442 435.382 181.442L432.889 181.198L433.145 179.489C433.145 179.489 434.173 179.586 434.428 177.984C434.682 176.383 434.899 176.882 434.899 176.882L436.521 176.591Z" fill="white" />
      </mask>
      <g mask="url(#mask60)">
        <path fillRule="evenodd" clipRule="evenodd" d="M433.486 176.628C433.486 176.628 433.456 179.006 433.892 179.535C434.328 180.065 434.968 180.146 435.726 179.186C436.483 178.226 436.819 176.628 436.819 176.628H433.486Z" fill="#E98B80" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M431.501 191.327C431.501 191.327 431.518 198.828 431.847 199.726C432.176 200.625 433.132 207.805 433.132 207.805C433.132 207.805 433.472 208.265 433.777 208.163C434.082 208.061 434.289 207.805 434.289 207.805C434.289 207.805 434.704 203.451 434.704 201.927C434.704 200.402 434.289 199.006 434.289 199.006L435.382 190.672L431.501 191.327Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M434.715 191.904C434.715 191.904 435.124 199.405 435.453 200.303C435.782 201.201 436.346 208.382 436.346 208.382C436.346 208.382 436.673 208.882 436.978 208.78C437.283 208.677 437.503 208.382 437.503 208.382C437.503 208.382 437.917 204.028 437.917 202.503C437.917 200.979 437.895 199.583 437.895 199.583L438.596 191.249L434.715 191.904Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M431.202 180.735C430.687 182.764 430.874 185.77 430.304 186.412C429.873 186.896 428.316 188.635 427.597 189.059C427.242 189.268 427.997 189.671 428.141 189.646C429.103 189.474 430.708 188.681 431.523 187.195C432.626 185.185 432.889 180.532 432.889 180.532C432.889 180.532 431.717 178.706 431.202 180.735Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M431.601 179.865C431.834 179.43 432.487 179.274 433.293 179.3C433.855 179.317 433.427 180.548 434.452 180.864C435.478 181.18 436.154 179.895 436.602 180.089C436.602 180.089 439.265 180.421 439.265 182.167C439.265 183.76 437.915 185.99 437.915 187.493C437.915 189.445 440.748 196.807 438.301 198.842C435.854 200.878 431.36 198.846 430.353 197.629C429.345 196.412 432.144 188.479 432.086 187.493C431.988 185.826 431.072 186.32 430.934 185.059C430.859 184.371 431.29 183.683 431.414 182.758C431.58 181.522 431.48 180.092 431.601 179.865Z" fill="url(#paint72_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M431.601 179.865C431.834 179.43 432.487 179.274 433.293 179.3C433.855 179.317 433.427 180.548 434.452 180.864C435.478 181.18 436.154 179.895 436.602 180.089C436.602 180.089 439.265 180.421 439.265 182.167C439.265 183.76 437.915 185.99 437.915 187.493C437.915 189.445 440.748 196.807 438.301 198.842C435.854 200.878 431.36 198.846 430.353 197.629C429.345 196.412 432.144 188.479 432.086 187.493C431.988 185.826 431.072 186.32 430.934 185.059C430.859 184.371 431.29 183.683 431.414 182.758C431.58 181.522 431.48 180.092 431.601 179.865Z" fill="url(#paint73_linear)" />
      <mask id="mask61" mask-type="alpha" maskUnits="userSpaceOnUse" x="430" y="179" width="10" height="21">
        <path fillRule="evenodd" clipRule="evenodd" d="M431.601 179.865C431.834 179.43 432.487 179.274 433.293 179.3C433.855 179.317 433.427 180.548 434.452 180.864C435.478 181.18 436.154 179.895 436.602 180.089C436.602 180.089 439.265 180.421 439.265 182.167C439.265 183.76 437.915 185.99 437.915 187.493C437.915 189.445 440.748 196.807 438.301 198.842C435.854 200.878 431.36 198.846 430.353 197.629C429.345 196.412 432.144 188.479 432.086 187.493C431.988 185.826 431.072 186.32 430.934 185.059C430.859 184.371 431.29 183.683 431.414 182.758C431.58 181.522 431.48 180.092 431.601 179.865Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M431.601 179.865C431.834 179.43 432.487 179.274 433.293 179.3C433.855 179.317 433.427 180.548 434.452 180.864C435.478 181.18 436.154 179.895 436.602 180.089C436.602 180.089 439.265 180.421 439.265 182.167C439.265 183.76 437.915 185.99 437.915 187.493C437.915 189.445 440.748 196.807 438.301 198.842C435.854 200.878 431.36 198.846 430.353 197.629C429.345 196.412 432.144 188.479 432.086 187.493C431.988 185.826 431.072 186.32 430.934 185.059C430.859 184.371 431.29 183.683 431.414 182.758C431.58 181.522 431.48 180.092 431.601 179.865Z" stroke="white" />
      </mask>
      <g mask="url(#mask61)">
        <path fillRule="evenodd" clipRule="evenodd" d="M437.77 185.672C437.77 185.672 436.38 190.007 437.471 190.755C438.561 191.504 439.262 199.427 439.262 199.427V197.884L442.663 191.778L437.77 185.672Z" fill="#0590E1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M431.501 186.17C431.501 186.17 434.048 187.458 434.715 187.458C435.382 187.458 433.16 187.949 432.71 188.27C432.26 188.592 431.223 188.474 431.223 188.474L431.501 186.065" fill="#0590E1" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M435.838 187.969C435.838 187.969 437.697 180.68 438.664 180.819C439.63 180.957 440.192 182.316 438.573 186.08C438.078 187.228 437.562 188.922 437.139 189.282C436.973 189.423 433.924 190.362 432.711 190.408C432.444 190.418 432.331 189.832 432.331 189.832L435.838 187.969Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M433.29 176.244C433.29 176.244 433.26 178.621 433.696 179.151C434.132 179.68 434.772 179.762 435.529 178.802C436.286 177.841 436.623 176.244 436.623 176.244H433.29Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M436.373 177.892C436.619 177.892 436.819 177.609 436.819 177.26C436.819 176.911 436.619 176.628 436.373 176.628C436.126 176.628 435.926 176.911 435.926 177.26C435.926 177.609 436.126 177.892 436.373 177.892Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M428.267 188.703C428.267 188.703 428.177 188.714 428.133 188.72C426.629 188.912 426.014 189.06 426.288 189.163C426.711 189.321 427.089 189.169 426.688 189.521C426.288 189.874 425.691 190.236 426.19 190.447C426.342 190.512 426.607 190.771 426.909 190.672C427.598 190.447 428.511 189.6 428.938 189.163C429.552 188.533 428.267 188.703 428.267 188.703Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M432.865 189.488C432.865 189.488 430.996 189.319 431.141 189.488C431.286 189.658 431.731 189.847 431.501 189.847C431.271 189.847 430.484 189.975 430.33 190.238C430.175 190.502 430.948 191.249 431.141 191.249C431.334 191.249 433.442 190.238 433.442 190.238L432.865 189.488Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M436.373 175.35C436.373 175.35 434.643 177.26 433.288 177.26C431.933 177.26 433.08 175.35 433.08 175.35H436.373Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M435.186 175.25C435.186 175.25 435.558 176.875 436.177 176.875L436.932 176.429C436.932 176.429 437.078 176.23 436.623 175.923C436.168 175.615 435.186 175.25 435.186 175.25Z" fill="#222222" />
      <g opacity="0.327125" filter="url(#filter94_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M462.177 223.499C472.514 223.499 480.893 221.589 480.893 219.232C480.893 216.875 472.514 214.964 462.177 214.964C451.84 214.964 443.46 216.875 443.46 219.232C443.46 221.589 451.84 223.499 462.177 223.499Z" fill="#0E0B0B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M446.256 193.866C446.256 193.866 445.127 192.453 445.036 192.919C444.945 193.385 444.091 192.772 443.78 192.936C443.469 193.099 443.954 194.164 444.382 194.247C444.809 194.329 446.283 194.337 446.283 194.337L446.256 193.866Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M454.637 189.713C454.637 189.713 455.897 189.939 455.897 191.068C455.897 192.197 455.897 193.259 455.897 193.259L450.42 196.78L445.643 194.494L446.113 193.572L449.738 194.216L454.637 189.713Z" fill="#D6EFFC" />
      <path fillRule="evenodd" clipRule="evenodd" d="M450.539 219.692C450.539 219.692 450.032 219.878 449.812 219.936C448.742 220.215 447.475 220.031 446.574 220.391C446.024 220.612 446.439 221.437 446.999 221.63C447.846 221.922 449.022 222.301 449.697 222.407C450.839 222.585 451.094 222.068 451.094 222.068L450.539 219.692Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M449.611 185.4C449.611 185.4 449.355 187.568 449.611 188.186C449.868 188.805 450.615 188.294 450.943 188.782C451.271 189.269 451.027 190.767 451.027 190.767L455.106 191.015L453.722 189.499C453.403 189.15 453.203 188.708 453.153 188.238L452.884 185.743L449.611 185.4Z" fill="#F49F95" />
      <mask id="mask62" mask-type="alpha" maskUnits="userSpaceOnUse" x="449" y="185" width="7" height="7">
        <path fillRule="evenodd" clipRule="evenodd" d="M449.611 185.4C449.611 185.4 449.355 187.568 449.611 188.186C449.868 188.805 450.615 188.294 450.943 188.782C451.271 189.269 451.027 190.767 451.027 190.767L455.106 191.015L453.722 189.499C453.403 189.15 453.203 188.708 453.153 188.238L452.884 185.743L449.611 185.4Z" fill="white" />
      </mask>
      <g mask="url(#mask62)">
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M453.279 188.042C453.117 188.321 453.01 188.112 452.584 188.112C452.159 188.112 452.159 188.328 451.669 188.382C451.426 188.409 450.791 187.673 450.231 186.836C450.143 186.706 449.985 186.969 449.903 186.836C449.787 186.648 449.751 186.064 449.654 185.883C449.374 185.36 449.114 183.376 451.324 183.376C453.534 183.376 452.857 184.789 453.279 184.789C453.702 184.789 453.848 185.214 453.59 186.382C453.333 187.549 453.442 187.763 453.279 188.042Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M450.513 187.477C450.23 187.477 450 187.232 450 186.929C450 186.627 450.23 186.382 450.513 186.382C450.797 186.382 451.027 186.627 451.027 186.929C451.027 187.232 450.797 187.477 450.513 187.477Z" fill="#F49F95" />
      <path fillRule="evenodd" clipRule="evenodd" d="M455.262 217.346C455.262 217.346 454.755 217.532 454.534 217.589C453.465 217.869 452.198 217.684 451.297 218.045C450.746 218.265 451.161 219.09 451.722 219.284C452.569 219.576 453.744 219.955 454.42 220.06C455.561 220.239 455.817 219.721 455.817 219.721L455.262 217.346Z" fill="#222222" />
      <path fillRule="evenodd" clipRule="evenodd" d="M455.896 200.764C455.896 200.764 453.628 201.283 452.29 201.116C450.953 200.949 449.67 199.986 449.67 199.986L448.198 209.245C448.056 210.135 448.045 211.04 448.164 211.933L449.251 220.108C449.3 220.472 449.61 220.743 449.976 220.743C450.334 220.743 450.639 220.485 450.692 220.131C450.832 219.191 451.077 217.382 451.077 216.242C451.077 215.784 451.038 215.236 450.981 214.686C450.797 212.893 450.971 211.068 451.674 209.409L451.917 208.837L453.825 218.004C453.909 218.408 454.296 218.675 454.704 218.611C455.08 218.552 455.358 218.227 455.358 217.846V208.202L455.896 200.764Z" fill="url(#paint74_linear)" />
      <mask id="mask63" mask-type="alpha" maskUnits="userSpaceOnUse" x="448" y="199" width="8" height="22">
        <path fillRule="evenodd" clipRule="evenodd" d="M455.896 200.764C455.896 200.764 453.628 201.283 452.29 201.116C450.953 200.949 449.67 199.986 449.67 199.986L448.198 209.245C448.056 210.135 448.045 211.04 448.164 211.933L449.251 220.108C449.3 220.472 449.61 220.743 449.976 220.743C450.334 220.743 450.639 220.485 450.692 220.131C450.832 219.191 451.077 217.382 451.077 216.242C451.077 215.784 451.038 215.236 450.981 214.686C450.797 212.893 450.971 211.068 451.674 209.409L451.917 208.837L453.825 218.004C453.909 218.408 454.296 218.675 454.704 218.611C455.08 218.552 455.358 218.227 455.358 217.846V208.202L455.896 200.764Z" fill="white" />
      </mask>
      <g mask="url(#mask63)">
        <path fillRule="evenodd" clipRule="evenodd" d="M451.768 209.435C451.768 209.435 452.991 205.575 453.149 205.482C453.307 205.389 453.593 207.245 452.882 208.778C452.172 210.312 452.882 214.93 452.882 214.93L451.768 209.435Z" fill="#00629C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M450.697 196.78C450.697 196.78 450.682 203.553 449.724 205.936C448.766 208.319 448.436 211.754 448.601 215.909C448.766 220.063 445.826 215.909 445.826 215.909V201.81L450.697 196.78Z" fill="#00629C" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M456.29 203.273C456.29 203.273 456.827 193.586 456.29 191.266C455.691 188.673 453.357 189.264 452.935 189.333C452.131 189.464 450.287 189.47 449.677 190.201C448.607 191.485 449.787 195.473 449.787 195.473L447.692 205.153C447.692 205.153 453.049 206.561 454.293 206.561C455.536 206.561 456.29 203.273 456.29 203.273Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M456.29 203.273C456.29 203.273 456.827 193.586 456.29 191.266C455.691 188.673 453.357 189.264 452.935 189.333C452.131 189.464 450.287 189.47 449.677 190.201C448.607 191.485 449.787 195.473 449.787 195.473L447.692 205.153C447.692 205.153 453.049 206.561 454.293 206.561C455.536 206.561 456.29 203.273 456.29 203.273Z" fill="url(#paint75_linear)" />
      <mask id="mask64" mask-type="alpha" maskUnits="userSpaceOnUse" x="447" y="189" width="10" height="18">
        <path fillRule="evenodd" clipRule="evenodd" d="M456.29 203.273C456.29 203.273 456.827 193.586 456.29 191.266C455.691 188.673 453.357 189.264 452.935 189.333C452.131 189.464 450.287 189.47 449.677 190.201C448.607 191.485 449.787 195.473 449.787 195.473L447.692 205.153C447.692 205.153 453.049 206.561 454.293 206.561C455.536 206.561 456.29 203.273 456.29 203.273Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M456.29 203.273C456.29 203.273 456.827 193.586 456.29 191.266C455.691 188.673 453.357 189.264 452.935 189.333C452.131 189.464 450.287 189.47 449.677 190.201C448.607 191.485 449.787 195.473 449.787 195.473L447.692 205.153C447.692 205.153 453.049 206.561 454.293 206.561C455.536 206.561 456.29 203.273 456.29 203.273Z" stroke="white" />
      </mask>
      <g mask="url(#mask64)">
        <path fillRule="evenodd" clipRule="evenodd" d="M450.271 190.13C450.966 190.373 451.399 190.657 451.643 192.874C451.887 195.091 452.358 194.795 451.83 197.894C451.302 200.993 449.984 202.613 449.984 202.613L448.97 202.349L449.877 198.02L449.197 190.925C449.197 190.925 449.575 189.887 450.271 190.13Z" fill="#D6EFFC" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M449.106 202.051C449.106 202.051 449.439 203.825 449.025 203.588C448.611 203.35 448.517 204.392 448.188 204.518C447.86 204.644 447.369 203.582 447.587 203.207C447.805 202.832 448.765 201.722 448.765 201.722L449.106 202.051Z" fill="#F49F95" />
      <mask id="mask65" mask-type="alpha" maskUnits="userSpaceOnUse" x="447" y="201" width="3" height="4">
        <path fillRule="evenodd" clipRule="evenodd" d="M449.106 202.051C449.106 202.051 449.439 203.825 449.025 203.588C448.611 203.35 448.517 204.392 448.188 204.518C447.86 204.644 447.369 203.582 447.587 203.207C447.805 202.832 448.765 201.722 448.765 201.722L449.106 202.051Z" fill="white" />
      </mask>
      <g mask="url(#mask65)">
        <path fillRule="evenodd" clipRule="evenodd" d="M449.025 203.588C449.025 203.588 448.693 202.955 448.603 203.271C448.579 203.356 448.014 203.424 447.985 203.588C447.942 203.832 447.985 204.898 447.985 204.898L449.025 203.588Z" fill="#E98B80" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M450.27 190.13C450.966 190.373 451.398 190.657 451.643 192.874C451.887 195.091 451.149 198.099 451.149 198.099L449.197 202.613L448.183 202.349L449.09 198.02L449.197 190.925C449.197 190.925 449.575 189.887 450.27 190.13Z" fill="white" />
      <defs>
        <filter id="filter0_f" x="59.0351" y="28.0678" width="353.495" height="214.78" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter1_f" x="230.059" y="69.9671" width="73.6439" height="62.7918" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter2_f" x="192.563" y="61.3543" width="21.2696" height="20.6497" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.07488" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter3_f" x="198.401" y="57.9106" width="9.84918" height="27.0803" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.07488" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter4_f" x="178.007" y="71.2053" width="55.6737" height="52.4892" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter5_f" x="13.743" y="130.508" width="51.1622" height="87.0525" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.2751" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter6_f" x="41.5367" y="157.792" width="51.1622" height="87.0525" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.2751" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter7_f" x="278.965" y="102.027" width="99.4264" height="46.069" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter8_f" x="258.576" y="107.325" width="61.4782" height="40.1812" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter9_f" x="285.889" y="68.6469" width="50.4111" height="57.9232" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter10_f" x="252.31" y="64.181" width="50.4111" height="57.9232" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter11_f" x="267.983" y="91.5957" width="47.9976" height="25.0901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter12_f" x="262.824" y="91.5957" width="45.1781" height="33.0051" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter13_f" x="261.954" y="170.125" width="174.255" height="90.4476" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.43656" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter14_f" x="309.527" y="241.412" width="50.8348" height="24.3401" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.03081" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter15_f" x="379.97" y="189.272" width="136.722" height="158.105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.43656" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter16_f" x="433.489" y="54.6828" width="203.963" height="159.883" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter17_f" x="393.724" y="103.758" width="50.9954" height="87.1063" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.2751" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter18_f" x="402.792" y="117.411" width="50.9954" height="87.1063" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.2751" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter19_f" x="485.176" y="121.7" width="76.2704" height="73.7564" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.07488" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter20_f" x="512.413" y="121.351" width="50.4971" height="57.7907" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter21_f" x="546.104" y="116.902" width="50.4971" height="57.7907" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter22_f" x="552.239" y="146.856" width="50.4971" height="57.7907" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter23_f" x="532.776" y="144.238" width="48.1241" height="25.0295" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter24_f" x="540.781" y="144.238" width="45.2952" height="32.9132" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter25_f" x="518.461" y="107.357" width="45.3406" height="51.1915" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter26_f" x="554.251" y="169.251" width="98.4976" height="45.4976" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter27_f" x="540.051" y="147.843" width="40.7235" height="46.4657" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.88583" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter28_f" x="567.425" y="144.254" width="40.7235" height="46.4657" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.88583" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter29_f" x="572.409" y="168.426" width="40.7235" height="46.4657" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.88583" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter30_f" x="-9.39889" y="147.529" width="49.7283" height="58.0545" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter31_f" x="23.2984" y="143.046" width="49.7283" height="58.0545" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter32_f" x="29.2527" y="173.236" width="49.7283" height="58.0545" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter33_f" x="-1.87602" y="139.723" width="38.9053" height="70.3671" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.22146" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter34_f" x="10.5795" y="170.539" width="46.9931" height="25.1501" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter35_f" x="18.3483" y="170.539" width="44.2476" height="33.0962" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter36_f" x="-3.52975" y="133.425" width="44.7239" height="51.4031" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter37_f" x="24.2361" y="172.09" width="40.9941" height="46.4457" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter38_f" x="45.6451" y="169.155" width="40.9941" height="46.4457" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter39_f" x="49.5437" y="188.922" width="40.9941" height="46.4457" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter40_f" x="32.5354" y="170.352" width="27.1605" height="47.7605" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.22146" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter41_f" x="39.8473" y="189.686" width="34.1428" height="19.8409" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter42_f" x="44.9342" y="189.686" width="32.3452" height="25.0437" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter43_f" x="28.0789" y="162.855" width="37.7174" height="42.0906" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter44_f" x="289.251" y="373.251" width="98.4976" height="45.4976" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter45_f" x="103.228" y="226.613" width="175.005" height="152.934" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter46_f" x="1.69234" y="270.891" width="233.403" height="110.445" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter47_f" x="68.0819" y="309.775" width="29.7011" height="33.135" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.732874" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter48_f" x="128.913" y="297.411" width="69.8869" height="59.9384" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter49_f" x="55.4406" y="235.212" width="125.743" height="69.0468" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.07488" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter50_f" x="54.3657" y="234.138" width="118.946" height="71.1966" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.61232" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter51_f" x="48.9705" y="225.062" width="117.337" height="79.3278" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.29953" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter52_f" x="185.919" y="314.626" width="99.4979" height="46.1696" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter53_f" x="191.888" y="287.947" width="44.3979" height="50.2531" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter54_f" x="166.08" y="284.504" width="44.3979" height="50.2531" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter55_f" x="179.823" y="307.319" width="39.1506" height="21.5822" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter56_f" x="175.858" y="307.319" width="36.9836" height="27.6847" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter57_f" x="176.799" y="326.955" width="99.4979" height="46.1696" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter58_f" x="171.08" y="293.684" width="50.4349" height="58.0604" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter59_f" x="137.47" y="289.2" width="50.4349" height="58.0604" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter60_f" x="153.151" y="316.697" width="48.0326" height="25.1528" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter61_f" x="147.987" y="316.697" width="45.2105" height="33.1003" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter62_f" x="386.247" y="328.658" width="221.612" height="146.058" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.44929" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter63_f" x="274.751" y="337.896" width="260.086" height="120.105" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.44929" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter64_f" x="269.965" y="342.696" width="273.966" height="129.12" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.44929" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter65_f" x="255.199" y="318.304" width="61.1803" height="104.426" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.13012" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter66_f" x="269.228" y="352.94" width="61.1803" height="104.426" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.13012" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter67_f" x="221.356" y="231.611" width="116.266" height="140.366" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="18.058" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter68_f" x="285.866" y="319.085" width="39.847" height="35.963" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.732874" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter69_f" x="345.057" y="225.421" width="71.9727" height="52.6245" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.15943" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter70_f" x="277.189" y="229.501" width="98.6782" height="66.4976" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.15943" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter71_f" x="286.76" y="233.022" width="106.406" height="67.8939" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.28986" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter72_f" x="324.954" y="361.483" width="78.5415" height="71.0059" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.15943" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter73_f" x="332.898" y="357.34" width="46.5908" height="67.4107" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.28986" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter74_f" x="417.622" y="339.704" width="83.507" height="71.7178" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.44929" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter75_f" x="379.588" y="316.199" width="74.0006" height="42.6821" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.28986" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter76_f" x="379.588" y="316.199" width="74.0006" height="38.3621" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.28986" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter77_f" x="375.718" y="312.33" width="74.0817" height="47.0613" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="3.22465" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter78_f" x="327.907" y="288.519" width="118.191" height="65.7032" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="1.28986" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter79_f" x="469.09" y="385.213" width="99.0179" height="46.0582" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.37441" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter80_f" x="493.932" y="370.484" width="50.2749" height="57.9085" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter81_f" x="460.528" y="366.02" width="50.2749" height="57.9085" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter82_f" x="476.158" y="393.426" width="47.7972" height="25.0834" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter83_f" x="471.026" y="393.426" width="44.9924" height="32.995" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter84_f" x="226.468" y="338.211" width="50.2749" height="57.9085" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter85_f" x="259.871" y="333.747" width="50.2749" height="57.9085" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter86_f" x="265.954" y="363.807" width="50.2749" height="57.9085" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter87_f" x="246.719" y="361.153" width="47.7972" height="25.0834" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter88_f" x="254.656" y="361.153" width="44.9924" height="32.995" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.44291" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter89_f" x="232.463" y="324.168" width="45.1624" height="51.286" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="6.10728" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter90_f" x="257.596" y="363.307" width="40.2199" height="46.3268" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.88583" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter91_f" x="284.318" y="359.736" width="40.2199" height="46.3268" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.88583" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter92_f" x="289.185" y="383.783" width="40.2199" height="46.3268" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.88583" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter93_f" x="421.04" y="198.628" width="54.22" height="24.6965" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.03081" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter94_f" x="435.399" y="206.902" width="53.556" height="24.6584" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="4.03081" result="effect1_foregroundBlur" />
        </filter>
        <linearGradient id="paint0_linear" x1="235.267" y1="23.965" x2="231.737" y2="92.5614" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A3DB" />
          <stop offset="1" stopColor="#0178BE" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="186.985" y1="105.613" x2="320.345" y2="49.6393" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B1DFFB" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="196.116" y1="82.1248" x2="278.064" y2="44.1152" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D1EEFF" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint3_linear" x1="26.637" y1="235.653" x2="370.667" y2="358.092" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint4_linear" x1="208.82" y1="40.0607" x2="208.82" y2="103.139" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A3DB" />
          <stop offset="1" stopColor="#0178BE" />
        </linearGradient>
        <linearGradient id="paint5_linear" x1="129.457" y1="104.911" x2="304.633" y2="38.9241" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8F6FF" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint6_linear" x1="127.474" y1="90.0026" x2="251.444" y2="32.6185" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B1DFFB" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint7_linear" x1="77.8104" y1="68.4151" x2="88.1822" y2="141.759" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A3DB" />
          <stop offset="1" stopColor="#0178BE" />
        </linearGradient>
        <linearGradient id="paint8_linear" x1="-16.5002" y1="192.716" x2="87.0878" y2="273.018" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint9_linear" x1="57.9943" y1="188.634" x2="226.865" y2="90.361" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D7F0FF" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint10_linear" x1="48.0879" y1="176.942" x2="187.85" y2="106.407" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B1DFFB" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint11_linear" x1="85.8291" y1="142.401" x2="167.776" y2="104.391" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D1EEFF" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint12_linear" x1="312.329" y1="130.507" x2="280.706" y2="130.507" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D6BF" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint13_linear" x1="308.627" y1="107.483" x2="283.26" y2="107.483" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D6BF" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint14_linear" x1="479" y1="180" x2="479" y2="312" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint15_linear" x1="311.336" y1="255.251" x2="326.668" y2="258.13" gradientUnits="userSpaceOnUse">
          <stop stopColor="#51AAE2" />
          <stop offset="1" stopColor="#0187C8" />
        </linearGradient>
        <linearGradient id="paint16_linear" x1="313.545" y1="232.162" x2="317.227" y2="235.977" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFBC79" />
          <stop offset="1" stopColor="#FF8744" />
        </linearGradient>
        <linearGradient id="paint17_linear" x1="289" y1="57" x2="289" y2="114" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint18_linear" x1="466.291" y1="83.5965" x2="463.596" y2="154.9" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A3DB" />
          <stop offset="1" stopColor="#0178BE" />
        </linearGradient>
        <linearGradient id="paint19_linear" x1="501.758" y1="209.518" x2="539.955" y2="181.915" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ACDBF8" />
          <stop offset="1" stopColor="#0471B4" />
        </linearGradient>
        <linearGradient id="paint20_linear" x1="501.758" y1="205.44" x2="537.712" y2="177.194" gradientUnits="userSpaceOnUse">
          <stop stopColor="#78C0EC" />
          <stop offset="1" stopColor="#0471B4" />
        </linearGradient>
        <linearGradient id="paint21_linear" x1="501.758" y1="193.499" x2="539.955" y2="165.896" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ACDBF8" />
          <stop offset="1" stopColor="#0471B4" />
        </linearGradient>
        <linearGradient id="paint22_linear" x1="501.758" y1="189.421" x2="537.712" y2="161.175" gradientUnits="userSpaceOnUse">
          <stop stopColor="#78C0EC" />
          <stop offset="1" stopColor="#0471B4" />
        </linearGradient>
        <linearGradient id="paint23_linear" x1="313.336" y1="177.418" x2="443.236" y2="285.63" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint24_linear" x1="490.097" y1="210.567" x2="424.526" y2="166.506" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint25_linear" x1="490.097" y1="194.548" x2="424.526" y2="150.487" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint26_linear" x1="435.833" y1="179.73" x2="370.262" y2="135.67" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint27_linear" x1="435.833" y1="163.711" x2="370.262" y2="119.65" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint28_linear" x1="394.772" y1="192.003" x2="585.378" y2="104.748" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B1DFFB" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint29_linear" x1="506.601" y1="129.398" x2="430.848" y2="94.4605" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ACDDFF" />
          <stop offset="1" stopColor="#D1EEFF" />
        </linearGradient>
        <linearGradient id="paint30_linear" x1="536.424" y1="183.014" x2="568.151" y2="183.014" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint31_linear" x1="540.138" y1="160.081" x2="565.589" y2="160.081" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint32_linear" x1="559.407" y1="197.519" x2="585.185" y2="197.519" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint33_linear" x1="150" y1="97" x2="150" y2="235" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint34_linear" x1="118" y1="176" x2="118" y2="314" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint35_linear" x1="269" y1="135" x2="269" y2="197" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint36_linear" x1="158" y1="230" x2="158" y2="325" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint37_linear" x1="385" y1="152" x2="385" y2="283" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint38_linear" x1="14.2643" y1="209.583" x2="45.0558" y2="209.583" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint39_linear" x1="17.8687" y1="186.469" x2="42.5693" y2="186.469" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint40_linear" x1="43.9468" y1="216.938" x2="64.1079" y2="216.938" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint41_linear" x1="46.3068" y1="201.803" x2="62.4798" y2="201.803" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint42_linear" x1="-41.1413" y1="380.535" x2="128.149" y2="426.942" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint43_linear" x1="79.7856" y1="236.769" x2="87.6442" y2="321.473" gradientUnits="userSpaceOnUse">
          <stop stopColor="#009CD8" />
          <stop offset="1" stopColor="#0169B4" />
        </linearGradient>
        <linearGradient id="paint44_linear" x1="16.7616" y1="293.565" x2="170.624" y2="222.425" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D1EEFF" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint45_linear" x1="31.1719" y1="340.568" x2="73.0092" y2="389.041" gradientUnits="userSpaceOnUse">
          <stop stopColor="#96CEF2" />
          <stop offset="1" stopColor="#3D99DB" />
        </linearGradient>
        <linearGradient id="paint46_linear" x1="144.669" y1="303.946" x2="176.36" y2="345.927" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A3DB" />
          <stop offset="0.91023" stopColor="#0175BC" />
          <stop offset="1" stopColor="#06538B" />
        </linearGradient>
        <linearGradient id="paint47_linear" x1="55.5855" y1="303.577" x2="-10.2661" y2="259.217" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint48_linear" x1="55.5855" y1="319.65" x2="-10.2661" y2="275.29" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint49_linear" x1="55.5855" y1="335.722" x2="-10.2661" y2="291.362" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint50_linear" x1="177.495" y1="293.885" x2="149.492" y2="237.843" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7BBAE5" />
          <stop offset="1" stopColor="#0281C4" />
        </linearGradient>
        <linearGradient id="paint51_linear" x1="215.035" y1="338.438" x2="190.732" y2="338.438" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D6BF" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint52_linear" x1="212.19" y1="320.686" x2="192.694" y2="320.686" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D6BF" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint53_linear" x1="197.532" y1="355.747" x2="165.881" y2="355.747" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D6BF" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint54_linear" x1="193.827" y1="332.629" x2="168.437" y2="332.629" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D6BF" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint55_linear" x1="196.622" y1="383.437" x2="386.224" y2="512.977" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2E8FC" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint56_linear" x1="350.523" y1="280.331" x2="358.017" y2="358.879" gradientUnits="userSpaceOnUse">
          <stop stopColor="#029BD5" />
          <stop offset="1" stopColor="#0169B4" />
        </linearGradient>
        <linearGradient id="paint57_linear" x1="293.663" y1="331.003" x2="432.556" y2="266.89" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B1DFFB" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint58_linear" x1="271.049" y1="250.399" x2="268.814" y2="300.079" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A2DB" />
          <stop offset="1" stopColor="#0169B4" />
        </linearGradient>
        <linearGradient id="paint59_linear" x1="337.122" y1="263.012" x2="395.772" y2="318.022" gradientUnits="userSpaceOnUse">
          <stop stopColor="#72D2FF" />
          <stop offset="1" stopColor="#59AFEE" />
        </linearGradient>
        <linearGradient id="paint60_linear" x1="340.231" y1="388.436" x2="375.857" y2="479.255" gradientUnits="userSpaceOnUse">
          <stop stopColor="#ABE4FF" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint61_linear" x1="277.16" y1="411.941" x2="369.695" y2="521.337" gradientUnits="userSpaceOnUse">
          <stop stopColor="#96CEF2" />
          <stop offset="1" stopColor="#5EADE6" />
        </linearGradient>
        <linearGradient id="paint62_linear" x1="284.291" y1="477.064" x2="379.249" y2="464.009" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A2DB" />
          <stop offset="1" stopColor="#0169B4" />
        </linearGradient>
        <linearGradient id="paint63_linear" x1="345.372" y1="336.86" x2="396.007" y2="349.736" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0169B0" />
          <stop offset="1" stopColor="#80C1EE" />
        </linearGradient>
        <linearGradient id="paint64_linear" x1="434.578" y1="364.694" x2="483.564" y2="416.663" gradientUnits="userSpaceOnUse">
          <stop stopColor="#02A3DB" />
          <stop offset="0.91023" stopColor="#0175BC" />
          <stop offset="1" stopColor="#06538B" />
        </linearGradient>
        <linearGradient id="paint65_linear" x1="473.727" y1="343.1" x2="440.241" y2="276.198" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7BBAE5" />
          <stop offset="1" stopColor="#0281C4" />
        </linearGradient>
        <linearGradient id="paint66_linear" x1="441.879" y1="296.798" x2="383.153" y2="242.623" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7BBAE5" />
          <stop offset="1" stopColor="#0281C4" />
        </linearGradient>
        <linearGradient id="paint67_linear" x1="520.296" y1="432.322" x2="488.839" y2="432.322" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D6BF" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint68_linear" x1="516.614" y1="409.308" x2="491.38" y2="409.308" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D6BF" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint69_linear" x1="250.378" y1="400.049" x2="281.835" y2="400.049" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint70_linear" x1="254.06" y1="377.035" x2="279.295" y2="377.035" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint71_linear" x1="276.724" y1="412.777" x2="301.889" y2="412.777" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBDFCB" />
          <stop offset="0.740549" stopColor="#EC8640" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint72_linear" x1="422.923" y1="207.225" x2="444.079" y2="210.332" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AAEDFF" />
          <stop offset="1" stopColor="#1BABFF" />
        </linearGradient>
        <linearGradient id="paint73_linear" x1="430.955" y1="189.825" x2="444.645" y2="194.715" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopOpacity="0.5" />
        </linearGradient>
        <linearGradient id="paint74_linear" x1="460.743" y1="218.296" x2="446.836" y2="220.936" gradientUnits="userSpaceOnUse">
          <stop stopColor="#51AAE2" />
          <stop offset="1" stopColor="#0187C8" />
        </linearGradient>
        <linearGradient id="paint75_linear" x1="452.125" y1="193.561" x2="456.558" y2="193.561" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.5" />
          <stop offset="1" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Hospital;
