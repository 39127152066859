import { useMemo } from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import Hospital from './Hospital';

const useStyles = makeStyles((theme: Theme) => ({
  services: {
    padding: '75px 0 110px',
    maxWidth: 1170,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: 20,
    },
  },
  audioList: {
    overflowX: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 300px)',
    gap: 50,
    padding: '104px 10px 62px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  steps: {
    display: 'grid',
    gap: 30,
    [theme.breakpoints.down('lg')]: {
      gap: 30,
    },

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '450px 450px',
    },
    [theme.breakpoints.down('md')]: {
      padding: 20,
      gap: 30,
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '340px 340px',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 20,
      gridTemplateRows: '340px 340px',
    },
    alignSelf: 'center',
    justifyContent: 'center',
  },
  step: {
    width: 340,
    [theme.breakpoints.up('md')]: {
      width: 450,
    },
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
    padding: '15px 10px',
    border: '1px dashed rgba(237,145,82,.8)',
    position: 'relative',
  },
  stepNumber: {
    position: 'absolute',
    left: -10,
    top: -10,
    right: 'auto',
    bottom: 'auto',
    display: 'inline-block',
    width: 24,
    height: 24,
    borderRadius: '100%',
    backgroundColor: '#f06b11',
    color: '#fff',
    fonSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'center',
  },
  container: {
    margin: '0 auto',
  },
  mt50: {
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      gridTemplateRows: '1fr',
    },
  },
  secondStep: {
    // marginTop: 85,
    position: 'relative',
    transform: 'translatey(85px)',
    [theme.breakpoints.down('lg')]: {
      transform: 'translatey(170px)',
    },
    [theme.breakpoints.down('md')]: {
      transform: 'translatey(165px)',
    },
    [theme.breakpoints.down('sm')]: {
      transform: 'translatey(0)',
    },
  },
  header: {
    display: 'grid',
    [theme.breakpoints.down('lg')]: {
      padding: 20,
    },
    [theme.breakpoints.down('md')]: {
      padding: 20,
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: '30% 70%',
      padding: 20,
    },
  },
  stepContent: {
    padding: '10px',
  }
}));


function Services() {
  const styles = useStyles();
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)'), []);

  return (
    <div className={styles.services}>
      <div className={styles.header}>
        <Typography variant={isMobile ? "h6" : "h4"} color="primary" sx={{ fontFamily: "'Montserrat', monospace", wordSpacing: '-5px', display: 'flex', alignItems: 'center' }}>
          Underlying technology <br />
          powers the IPA <br />
          platform
        </Typography>
        <Hospital />
      </div>
      <div className={styles.steps}>
        <div className={styles.step}>
          <div className={styles.stepNumber}>1</div>
          <div className={styles.stepContent}>
            <Typography variant="h6" color="primary" sx={{ fontFamily: "'Montserrat', monospace", wordSpacing: '-5px', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
              Amaros Longitudinal Data
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Establishment of one of the most extensive longitudinal
              biomarker data sets from an ethnically diverse patient
              population for precision & predictive medicine
            </Typography>
          </div>
        </div>
        <div className={clsx(styles.step, styles.secondStep)}>
          <div className={styles.stepNumber}>2</div>
          <div className={styles.stepContent}>
            <Typography variant="h6" color="primary" sx={{ fontFamily: "'Montserrat', monospace", wordSpacing: '-5px', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
              Amaros Fusion Neural Network(AFNN)
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Industry-first neural network holistically integrates
              multi-modal data (medical imaging, genomics, EHR, claims, clinical studies)
              and predictive algorithms into a single searchable platform
            </Typography>
          </div>
        </div>
      </div>
      <div className={clsx(styles.steps, styles.mt50)}>
        <div className={styles.step}>
          <div className={styles.stepNumber}>3</div>
          <div className={styles.stepContent}>
            <Typography variant="h6" color="primary" sx={{ fontFamily: "'Montserrat', monospace", wordSpacing: '-5px', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
              Amaros Clinical Trial Identification &#38; Optimization Network (ACTION™)
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Establishment of one of the largest
              provider-based clinical trial network for patient recruitment
              & continued longitudinal data access
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services;
