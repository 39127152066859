import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-material-ui-carousel'

import a1 from '../assets/images/a1.jpg';
import a2 from '../assets/images/a2.jpg';
import a3 from '../assets/images/a3.jpg';
import a4 from '../assets/images/a4.jpg';
import a5 from '../assets/images/a5.jpg';
import a6 from '../assets/images/a6.jpg';
import a7 from '../assets/images/a7.jpg';
import a8 from '../assets/images/a8.jpg';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';


const images = [a1, a2, a3, a4, a5, a6, a7, a8];

const useStyles = makeStyles((theme: Theme) => ({
  example: {
    padding: '50px',
    width: 1170,
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 20,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 20,
    },

  },
  carouselWrapper: {
    '& img': {
      width: '100%',
    },
  },
  carousel: {
    height: 600,
    width: "100%",
    "& img": {
      height: 500,
      width: "-webkit-fill-available"
    }
  },
}));

function Examples() {
  const styles = useStyles();
  return (
    <section className={styles.example}>
      <div className={styles.carouselWrapper}>
        <Carousel
          className={styles.carousel}
          navButtonsAlwaysVisible
          NextIcon={<ChevronRight color="primary" />}
          PrevIcon={<ChevronLeft color="primary" />}
        >
          {
            images.map(src => <img key={src} src={src} alt="presentation" />)
          }
        </Carousel>
      </div>
    </section>
  );
}

export default Examples;
