import { useEffect, useState, useRef, useMemo } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  slider: {
    display: 'inline-block',
    overflowY: 'hidden',
    height: 58,
    [theme.breakpoints.down('lg')]: {
      height: 33,
    },
    [theme.breakpoints.down('md')]: {
      height: 33,
    },
    [theme.breakpoints.down('sm')]: {
      height: 19,
    },
    willChange: 'maxWidth',
    transition: 'all ease-out 500ms',
    cursor: 'pointer',
  },
  slide: {
    willChange: 'transform',
    overflow: 'hidden',
  }
}));

const wordsArr = [
  'Drive',
  'Elevate',
  'Accelerate',
  'Enhance',
  'Empower',
  'Transform',
  'Inspire',
];

function SlidingText() {
  const styles = useStyles();
  const [index, setIndex] = useState(0);
  const [widthArr, setWidthArr] = useState<number[]>([]);
  const [words, setWords] = useState(() => (wordsArr));
  const [needTransform, setNeedTransfrom] = useState(false);
  const ref = useRef<HTMLUListElement>(null);
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);

  const getWidth = (element: HTMLElement, child: number) => {
    const children = element && Array.from(element.children);
    // @ts-ignore
    const widthArr: number[] = children.map(el => el.children[0].offsetWidth);
    setWidthArr(widthArr);
  }

  useEffect(() => {
    getWidth(ref.current as HTMLElement, 0);
  }, [])

  useEffect(() => {
    if (widthArr.length) {
      const timer = setTimeout(() => {
        setIndex(prev => ++prev);
        setNeedTransfrom(true);
      }, 2000);
      return () => clearTimeout(timer)
    }
  }, [widthArr]);


  const handleSliderTranslateEnd = () => {
    const copyWords = [...words];
    const copyWidths = [...widthArr];
    const newWordsOrder = [...copyWords, ...copyWords.slice(0, 1)].slice(-copyWords.length);
    const newWidthsOrder = [...copyWidths, ...copyWidths.slice(0, 1)].slice(-copyWidths.length);
    getWidth(ref.current as HTMLElement, 1);
    setWidthArr(newWidthsOrder);
    setIndex(prev => --prev);
    setWords(newWordsOrder);
    setNeedTransfrom(false);
  };

  const height = useMemo(() => isMobile ? 25 : isIpad ? 36 : 72, [isMobile, isIpad])
  const getCurrentTranslateValue = () => {
    return `translateY(-${index * height}px)`;
  };

  return (
    <div
      className={styles.slider}
      style={{ maxWidth: widthArr[index] }}
    >
      <ul
        className={styles.slide}
        style={{
          transition: needTransform ? 'transform ease-out 500ms' : undefined,
          transform: getCurrentTranslateValue(),
        }}
        onTransitionEnd={handleSliderTranslateEnd}
        ref={ref}
      >
        {words.map(word => (
          <li key={word}>
            <em style={{ padding: '0 10px' }}>{word}</em>
          </li>
        ))
        }
      </ul>

    </div >
  );
}

export default SlidingText;
