import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    position: 'fixed',
    width: '100%',
    maxWidth: '100vw',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.appBar,
    background: '#fff',
    boxShadow: theme.shadows[2],
  },
  header: {
    height: 89,
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 40px',
      margin: '0 20px',
    },
    gridTemplateColumns: '1fr 180px 40px',
    gap: '10px',
    alignItems: 'center',
    margin: '0 40px',
  },
  logo: {
    width: 180,
    [theme.breakpoints.down('sm')]: {
      width: 100,

    },
    height: 'auto',
  },
  requestButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none !important',
    },
  },
  navDrawer: {
    zIndex: theme.zIndex.appBar + 1,
    position: 'fixed',
    top: 0,
    right: 0,
    maxWidth: 'calc(100% - 50%)',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'calc(100% - 150px)',
    },

    padding: '89px 30px 10px',
    width: '100%',
    height: '100%',
    backgroundColor: '#2b3337',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 30px)',
      padding: '30px 30px 10px',
      overflowY: 'auto',
    },
  },
  navDivider: {
    [theme.breakpoints.down('sm')]: {
      display: 'none !important',
    },
  },
  logo2: {
    width: 'auto',
    height: 35,
  },
  navHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff',
  },
  navContent: {
    marginTop: 40,
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 3px 1fr',
    },
    gap: 25,
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: '190px 40px 3px 1fr',
    },
  },
  routeInfo: {
    overflow: 'hidden',
  },
  routeWrapper: {
    marginBottom: 25,
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  route: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: 30,
    position: 'relative',
    '&:before': {
      content: "' '",
      position: 'absolute',
      bottom: '-5px',
      left: '0',
      width: '0',
      height: '2px',
      background: '#fff',
      transform: 'scale3d(1,1,1)',
      transition: 'all .1s',
    },
    '&:hover': {
      "&:before": {
        background: '#ef9123',
        transform: 'scale3d(1, 1, 1)',
        transitionTimingFunction: 'cubic-bezier(.4, 0, .2, 1)',
        transitionDuration: '.3s',
        width: '100%',
      },
    },
  },
  subRouteWrapper: {
    height: 51,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  subRoute: {
    display: 'inline-block',
  },
  intro: {
    padding: '178px 40px 117px',
    background: '#006D75',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  intro2: {
    padding: 30,
    height: 2000,
  },
  floatingTextContent: {
    width: 1170,
    textAlign: 'left',
    margin: '0 auto',
    display: 'grid',
    gridTemplateRows: '1fr 56px 43px ',
    gap: 20,
  },
}));

export default useStyles;
