import { MouseEvent, useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import Slide from '@mui/material/Slide';
import { IconButton, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import logo2 from '../assets/images/logo.png';
import Button from '@mui/material/Button';

import useStyles from './styles';

const navItems = [
  {
    name: 'Work',
    route: '/',
    subRoutes: [
      {
        name: 'Case Studies',
        route: '/',
      },
      {
        name: 'Clients',
        route: '/',
      }
    ]
  },
  {
    name: 'What We Do',
    route: '/',
    subRoutes: [
      {
        name: 'Strategy',
        route: '/',
      },
      {
        name: 'Development',
        route: '/',
      },
      {
        name: 'Maintenance',
        route: '/',
      }
    ]
  },
  {
    name: 'About',
    route: '/',
  },
];

interface INav {
  isOpen?: boolean;
  toggleDrawer: () => void;
  selectRoutInfoIndex: (e: MouseEvent) => void;
  idx: number;
  openRequestDemo?: () => void;
}

function Nav({ isOpen, toggleDrawer, selectRoutInfoIndex, idx, openRequestDemo }: INav) {
  const styles = useStyles();
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  return (
    <Slide direction="left" in={isOpen}>
      <nav className={styles.navDrawer}>
        <div className={styles.navHeader}>
          <img src={logo2} alt="amaros logo" className={styles.logo2} />
          <IconButton onClick={toggleDrawer} color="inherit">
            <CloseIcon color="inherit" />
          </IconButton>
        </div>
        <div className={styles.navContent}>
          <ul>
            {navItems.map(({ route, name, subRoutes }, i) =>
              <li key={name} className={styles.routeWrapper}>
                <Link to={route} className={styles.route}>{name}</Link>
                {subRoutes && (
                  <IconButton color={idx === i ? 'warning' : 'inherit'} onClick={selectRoutInfoIndex} data-id={i}>
                    <ChevronRightIcon color="inherit" fontSize="large" />
                  </IconButton>
                )}
              </li>)}
          </ul>
          {isMobile && (
            <Button
              variant="contained"
              color="warning"
              sx={{
                borderRadius: '0px',
                maxWidth: 300,
                '&:hover': {
                  backgroundColor: '#fff',
                  color: 'primary.main',
                }
              }}
              size="large"
              onClick={openRequestDemo}
            >
              Request Demo
            </Button>
          )}
          <Divider orientation={isMobile ? "horizontal" : "vertical"} sx={{ borderColor: '#fff' }} />
          <div className={styles.routeInfo}>
            {
              navItems[idx]
                ? navItems[idx].subRoutes?.map(({ name, route }, index) => (
                  <Slide
                    direction="right"
                    in
                    key={name}
                    timeout={{ enter: 300 * (index + 1) }}
                    unmountOnExit
                  >
                    <div className={styles.subRouteWrapper}>
                      <Link to={route} className={clsx(styles.subRoute, styles.route)}>{name}</Link>
                    </div>
                  </Slide>
                ))
                : !isMobile && (
                  <Slide direction="right" in={isOpen}>
                    <div>
                      <Typography color="inherit" variant="h6" sx={{ marginBottom: '25px', fontWeight: 300 }}>
                        801 Hermosa Way<br />
                        Menlo Park, CA 94025
                      </Typography>
                      <Typography color="inherit" variant="h6" sx={{ display: 'flex', marginBottom: '25px', fontWeight: 300 }} alignItems="center">
                        <EmailIcon color="inherit" /> <span> info@amaros.ai﻿</span>
                      </Typography>
                      <Button
                        variant="contained"
                        size="large"
                        color="warning"
                        sx={{ borderRadius: '0px', color: '#fff' }}
                      >
                        Contact us
                      </Button>
                    </div>
                  </Slide>
                )}

            <Slide direction="right" in={isMobile}>
              <div>
                <Typography color="inherit" variant="h6" sx={{ marginBottom: '25px', fontWeight: 300 }}>
                  801 Hermosa Way<br />
                  Menlo Park, CA 94025
                </Typography>
                <Typography color="inherit" variant="h6" sx={{ display: 'flex', marginBottom: '25px', fontWeight: 300 }} alignItems="center">
                  <EmailIcon color="inherit" /> <span> info@amaros.ai﻿</span>
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  color="warning"
                  sx={{ borderRadius: '0px', color: '#fff' }}
                >
                  Contact usv
                </Button>
              </div>
            </Slide>
          </div>
        </div>
      </nav>
    </Slide>
  );
}

export default Nav;
