import { useMemo } from 'react';

function Person() {
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);

  return (
    <svg width={isMobile ? "350" : "525"} height="397" viewBox="0 0 525 397" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M392.59 331.808L506.091 304.592L348 192.584L276.446 262.514L392.59 331.808Z" fill="#000C1D" />
      </g>
      <g filter="url(#filter1_f)">
        <path fillRule="evenodd" clipRule="evenodd" d="M163.196 236.771L307.628 319.819L205.573 378.163L60.67 295.094L163.196 236.771Z" fill="#000C1D" />
      </g>
      <path d="M143.059 293.89L244.539 353.963L332.611 294.541" stroke="url(#paint0_linear)" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M450.964 130.967L451.078 184.116L340.172 252.136" stroke="url(#paint1_linear)" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="2.02" />
      <path fillRule="evenodd" clipRule="evenodd" d="M102.526 211.703L246.958 290.422V294.75L144.902 353.094L0 270.025L0 265.696L102.526 211.703Z" fill="url(#paint2_linear)" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="211" width="247" height="143">
        <path fillRule="evenodd" clipRule="evenodd" d="M102.526 211.703L246.958 290.422V294.75L144.902 353.094L0 270.025L0 265.696L102.526 211.703Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <g filter="url(#filter2_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M215.956 306.34C238.626 306.34 257.004 287.964 257.004 265.298C257.004 242.631 238.626 224.256 215.956 224.256C193.285 224.256 174.907 242.631 174.907 265.298C174.907 287.964 193.285 306.34 215.956 306.34Z" fill="#4A9ED8" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M102.526 207.374L246.958 290.422L144.902 348.765L0 265.696L102.526 207.374Z" fill="url(#paint3_linear)" />
      <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="207" width="247" height="142">
        <path fillRule="evenodd" clipRule="evenodd" d="M102.526 207.374L246.958 290.422L144.902 348.765L0 265.696L102.526 207.374Z" fill="white" />
      </mask>
      <g mask="url(#mask1)">
        <path fillRule="evenodd" clipRule="evenodd" d="M102.526 206.941L246.958 289.989L144.902 348.332L0 265.263L102.526 206.941Z" fill="url(#paint4_linear)" />
        <g filter="url(#filter3_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M23.4523 293.263C38.5637 293.263 50.814 281.015 50.814 265.906C50.814 250.797 38.5637 238.549 23.4523 238.549C8.34083 238.549 -3.90942 250.797 -3.90942 265.906C-3.90942 281.015 8.34083 293.263 23.4523 293.263Z" fill="#C6E7FB" />
        </g>
        <path d="M45.0977 271.156L110.827 231.153" stroke="#F9F9F9" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M65.8788 281.545L90.3422 266.656L118.674 249.414" stroke="#F9F9F9" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M85.361 293.232L117.359 273.758" stroke="#F9F9F9" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M107.008 306.218L172.737 266.215" stroke="#F9F9F9" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M174.321 291.412L194.384 279.201" stroke="#F9F9F9" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M272.313 1.46292C271.262 0.823394 269.94 0.834444 268.899 1.49144L258.661 7.95724L266.464 266.922C266.579 270.726 268.509 274.244 271.654 276.385L380.439 350.431L392.194 343.411C393.173 342.826 393.772 341.77 393.772 340.629V82.0378C393.772 77.8909 391.61 74.0441 388.067 71.8886L272.313 1.46292Z" fill="url(#paint5_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M261.602 6.41062C259.443 5.09252 256.674 6.64641 256.674 9.17599V271.028C256.674 275.177 258.838 279.025 262.383 281.18L377.921 351.416C380.08 352.728 382.844 351.174 382.844 348.647V87.0958C382.844 82.9553 380.688 79.1136 377.154 76.9561L261.602 6.41062Z" fill="url(#paint6_linear)" />
      <mask id="mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="256" y="5" width="127" height="347">
        <path fillRule="evenodd" clipRule="evenodd" d="M261.602 6.41062C259.443 5.09252 256.674 6.64641 256.674 9.17599V271.028C256.674 275.177 258.838 279.025 262.383 281.18L377.921 351.416C380.08 352.728 382.844 351.174 382.844 348.647V87.0958C382.844 82.9553 380.688 79.1136 377.154 76.9561L261.602 6.41062Z" fill="white" />
      </mask>
      <g mask="url(#mask2)">
        <path fillRule="evenodd" clipRule="evenodd" d="M265.574 17.341C263.415 16.0255 260.648 17.5796 260.648 20.1079V268.254C260.648 272.406 262.816 276.257 266.366 278.411L371.963 342.474C374.122 343.784 376.883 342.23 376.883 339.704V91.8324C376.883 87.6881 374.724 83.8434 371.185 81.6871L348.807 68.0527C348.208 67.6879 347.451 67.704 346.869 68.0941L341.662 71.5791C341.08 71.9687 340.325 71.9853 339.727 71.6218L293.603 43.6085C292.384 42.8679 291.535 41.6452 291.268 40.2437L290.125 34.242C289.859 32.8429 289.013 31.6219 287.797 30.8808L265.574 17.341Z" fill="#C37343" />
        <g opacity="0.61277" filter="url(#filter4_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M357.732 255.62V279.517L279.438 233.267V210.405L357.732 255.62Z" fill="#BE540C" />
        </g>
        <g opacity="0.61277" filter="url(#filter5_f)">
          <path fillRule="evenodd" clipRule="evenodd" d="M372.349 144.458V230.912L249.29 162.332V72.9517L372.349 144.458Z" fill="#BE540C" />
        </g>
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M353.019 263.997V287.894L349.877 289.465L274.725 241.644L271.583 220.353L274.725 218.783L353.019 263.997Z" fill="url(#paint7_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M349.877 265.568V289.465L271.583 243.215V220.354L349.877 265.568Z" fill="url(#paint8_linear)" />
      <g opacity="0.797131" filter="url(#filter6_f)">
        <path d="M290.777 237.362L334.322 262.818" stroke="#DF6927" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path d="M287.111 240.504L330.656 265.96" stroke="#F9F9F9" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M107.008 122.885L191.224 76.7988L253.833 114.955" stroke="url(#paint9_linear)" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="2.02" />
      <path fillRule="evenodd" clipRule="evenodd" d="M411.84 110.864V169.144L414.458 170.191L487.551 126.229L490.17 69.3245L487.551 68.2773L411.84 110.864Z" fill="url(#paint10_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M414.458 111.911V170.192L490.17 127.276V69.3247L414.458 111.911Z" fill="url(#paint11_linear)" />
      <g opacity="0.563802" filter="url(#filter7_f)">
        <path d="M419.752 119.234L472.133 91.0903" stroke="#005C9E" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g opacity="0.563802" filter="url(#filter8_f)">
        <path d="M419.752 138.606L448.135 122.885" stroke="#005C9E" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path d="M428.654 122.899L481.035 94.7554" stroke="#F9F9F9" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M428.654 142.272L457.038 126.55" stroke="#F9F9F9" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M50.6667 120.808V179.088L53.285 180.135L126.378 136.172L128.996 79.2683L126.378 78.2212L50.6667 120.808Z" fill="url(#paint12_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M53.285 121.855V180.135L128.996 137.22V79.2686L53.285 121.855Z" fill="url(#paint13_linear)" />
      <g opacity="0.563802" filter="url(#filter9_f)">
        <path d="M61.0461 149.521C61.0461 149.521 69.9583 126.482 80.8197 130.659C91.6812 134.835 96.9162 134.38 99.796 125.593C102.676 116.807 104.349 96.9258 114.797 100.586" stroke="#005C9E" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path d="M64.0649 154.677C64.0649 154.677 72.9771 131.638 83.8385 135.814C94.7 139.991 99.935 139.536 102.815 130.749C105.695 121.962 107.368 102.082 117.816 105.742" stroke="#F9F9F9" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M361.706 121.93V208.384L357.517 211.002L238.647 139.805L234.458 53.0417L238.647 50.4238L361.706 121.93Z" fill="url(#paint14_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M357.517 124.548V211.002L234.458 142.422V53.0415L357.517 124.548Z" fill="url(#paint15_linear)" />
      <g opacity="0.797131" filter="url(#filter10_f)">
        <path d="M253.833 124.385L279.056 112.874L280.323 124.385H290.229L297.299 128.71L307.294 128.581L317.365 136.657L329.635 132.246L342.101 136.972L350.22 136.657" stroke="#DF6927" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path d="M243.36 129.621L268.583 118.11L269.849 129.621H279.756L286.826 133.946L296.821 133.817L306.892 141.893L319.162 137.482L331.628 142.208L339.747 141.893" stroke="#FFE6A9" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round" />
      <g opacity="0.797131" filter="url(#filter11_f)">
        <path d="M251.267 76.7983L278.916 92.3106" stroke="#DF6927" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path d="M247.601 79.9399L275.251 95.4522" stroke="#F9F9F9" strokeWidth="3.6" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M149.294 185.394C152.583 185.394 159.284 173.915 154.17 171.736C151.795 170.724 147.437 174.831 146.642 175.193C146.025 175.473 145.484 175.084 145.011 175.561C143.975 176.605 143.69 178.719 144.157 180.19C145.256 183.646 146.005 185.394 149.294 185.394Z" fill="#273741" />
      <path fillRule="evenodd" clipRule="evenodd" d="M160.782 196.909L162.412 201.84L172.727 207.194L171.791 209L160.06 206.261C159.186 206.057 158.479 205.415 158.193 204.564L156.03 198.142L160.782 196.909Z" fill="#AC908E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M157.13 187.697C157.13 187.697 162.707 199.317 162.341 200.665C161.976 202.013 155.31 202.666 155.31 202.666L154.303 194.665L157.13 187.697Z" fill="#F7E0BD" />
      <path opacity="0.402762" fillRule="evenodd" clipRule="evenodd" d="M158.904 255.544C158.904 255.544 133.352 241.732 127.05 245.652C120.748 249.572 143.629 261.155 143.629 261.155L146.986 257.564C147.728 256.77 148.743 256.285 149.827 256.206L158.904 255.544Z" fill="url(#paint16_linear)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M144.166 254.191L145.701 257.794C146.11 258.754 145.854 259.868 145.068 260.554C144.124 261.377 142.706 261.339 141.807 260.466L141.48 260.148C140.985 259.667 140.677 259.027 140.612 258.341L140.262 254.699C140.262 254.699 140.333 251.283 141.423 251.204C141.986 251.163 142.504 250.794 143.428 251.72C143.697 251.989 144.166 254.191 144.166 254.191Z" fill="#09466C" />
      <mask id="mask3" mask-type="alpha" maskUnits="userSpaceOnUse" x="140" y="251" width="6" height="11">
        <path fillRule="evenodd" clipRule="evenodd" d="M144.166 254.191L145.701 257.794C146.11 258.754 145.854 259.868 145.068 260.554C144.124 261.377 142.706 261.339 141.807 260.466L141.48 260.148C140.985 259.667 140.677 259.027 140.612 258.341L140.262 254.699C140.262 254.699 140.333 251.283 141.423 251.204C141.986 251.163 142.504 250.794 143.428 251.72C143.697 251.989 144.166 254.191 144.166 254.191Z" fill="white" />
      </mask>
      <g mask="url(#mask3)">
        <path fillRule="evenodd" clipRule="evenodd" d="M146.387 258.434C146.387 258.434 145.306 260.811 143.438 260.378C141.569 259.944 140.489 257.417 140.489 257.417L139.374 262.029L144.594 262.637L146.387 258.434Z" fill="#03273E" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M152.612 249.864C152.612 249.864 154.096 251.328 154.957 251.968C155.818 252.609 157.494 253.221 158.165 253.995C158.381 254.245 158.49 254.545 158.538 254.837C158.664 255.597 158.094 256.239 157.42 256.61C156.542 257.094 155.476 257.089 154.603 256.598L150.208 254.124C149.673 253.823 149.373 253.228 149.449 252.618L149.795 249.864H152.612Z" fill="#09466C" />
      <mask id="mask4" mask-type="alpha" maskUnits="userSpaceOnUse" x="149" y="249" width="10" height="8">
        <path fillRule="evenodd" clipRule="evenodd" d="M152.612 249.864C152.612 249.864 154.096 251.328 154.957 251.968C155.818 252.609 157.494 253.221 158.165 253.995C158.381 254.245 158.49 254.545 158.538 254.837C158.664 255.597 158.094 256.239 157.42 256.61C156.542 257.094 155.476 257.089 154.603 256.598L150.208 254.124C149.673 253.823 149.373 253.228 149.449 252.618L149.795 249.864H152.612Z" fill="white" />
      </mask>
      <g mask="url(#mask4)">
        <path fillRule="evenodd" clipRule="evenodd" d="M156.86 257.388C154.042 255.381 150.384 253.76 149.966 253.083C149.548 252.405 149.299 251.968 149.299 251.968L148.244 253.856L152.889 257.665L156.568 257.785C156.568 257.785 159.678 259.395 156.86 257.388Z" fill="#03273E" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M156.008 207.088L156.915 229.528L153.729 251.066C152.467 251.561 151.257 252.77 149.835 251.066L150.51 230.397L146.599 208.025L156.008 207.088Z" fill="#3F8ED0" />
      <mask id="mask5" mask-type="alpha" maskUnits="userSpaceOnUse" x="146" y="207" width="11" height="45">
        <path fillRule="evenodd" clipRule="evenodd" d="M156.008 207.088L156.915 229.528L153.729 251.066C152.467 251.561 151.257 252.77 149.835 251.066L150.51 230.397L146.599 208.025L156.008 207.088Z" fill="white" />
      </mask>
      <g mask="url(#mask5)">
        <path fillRule="evenodd" clipRule="evenodd" d="M147.914 216.216C147.914 216.216 148.777 222.806 150.572 224.954C152.368 227.102 151.168 232.764 151.168 234.774C151.168 236.784 151.168 244.647 151.168 246.107C151.168 247.568 149.737 253.697 149.737 253.697L147.567 244.269L147.914 216.216Z" fill="#2B7EC4" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M148.929 206.379L144.154 253.321C142.787 254.797 141.492 254.732 140.26 253.321L139.806 208.021L148.929 206.379Z" fill="#3F8ED0" />
      <mask id="mask6" mask-type="alpha" maskUnits="userSpaceOnUse" x="139" y="206" width="10" height="49">
        <path fillRule="evenodd" clipRule="evenodd" d="M148.929 206.379L144.154 253.321C142.787 254.797 141.492 254.732 140.26 253.321L139.806 208.021L148.929 206.379Z" fill="white" />
      </mask>
      <g mask="url(#mask6)">
        <path fillRule="evenodd" clipRule="evenodd" d="M140.262 216.452C140.262 216.452 141.632 222.717 141.175 226.863C140.719 231.01 142.613 239.839 142.122 241.701C141.632 243.564 141.175 254.416 141.175 254.416L137.144 253.697L138.452 215.131L140.262 216.452Z" fill="#2B7EC4" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M138.979 188.022L140.41 197.044L138.452 211.89C138.452 211.89 142.559 214.934 147.68 214.715C152.801 214.496 156.695 210.564 156.695 210.564C156.695 210.564 156.212 206.09 156.212 201.254C156.212 197.513 156.909 191.068 157.224 188.342C157.312 187.582 156.896 186.857 156.174 186.605C154.417 185.99 151.05 185.034 148.247 185.541C144.254 186.264 138.979 188.022 138.979 188.022Z" fill="#FFEFD6" />
      <mask id="mask7" mask-type="alpha" maskUnits="userSpaceOnUse" x="138" y="185" width="20" height="30">
        <path fillRule="evenodd" clipRule="evenodd" d="M138.979 188.022L140.41 197.044L138.452 211.89C138.452 211.89 142.559 214.934 147.68 214.715C152.801 214.496 156.695 210.564 156.695 210.564C156.695 210.564 156.212 206.09 156.212 201.254C156.212 197.513 156.909 191.068 157.224 188.342C157.312 187.582 156.896 186.857 156.174 186.605C154.417 185.99 151.05 185.034 148.247 185.541C144.254 186.264 138.979 188.022 138.979 188.022Z" fill="white" />
      </mask>
      <g mask="url(#mask7)">
        <path fillRule="evenodd" clipRule="evenodd" d="M151.698 186.35L151.757 215.287L156.31 212.88L151.698 186.35Z" fill="#F2D09B" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M151.168 185.365L153.593 217.316L158.682 215.094L157.47 188.686L157.445 188.139C157.404 187.248 156.763 186.5 155.89 186.323L151.168 185.365Z" fill="#F4A832" />
      <path fillRule="evenodd" clipRule="evenodd" d="M146.826 185.806L147.144 218.331C147.144 218.331 143.983 218.647 142.403 218.331C140.823 218.015 137.662 216.434 137.662 216.434L138.979 188.022L146.826 185.806Z" fill="#F4A832" />
      <mask id="mask8" mask-type="alpha" maskUnits="userSpaceOnUse" x="137" y="185" width="11" height="34">
        <path fillRule="evenodd" clipRule="evenodd" d="M146.826 185.806L147.144 218.331C147.144 218.331 143.983 218.647 142.403 218.331C140.823 218.015 137.662 216.434 137.662 216.434L138.979 188.022L146.826 185.806Z" fill="white" />
      </mask>
      <g mask="url(#mask8)">
        <path fillRule="evenodd" clipRule="evenodd" d="M139.628 199.986C139.628 199.986 139.292 206.529 140.262 207.798C141.232 209.067 139.374 214.348 139.374 216.452C139.374 218.557 136.366 218.489 136.366 218.489L135.81 210.233L137.144 199.986H139.628Z" fill="#D88C15" />
      </g>
      <path d="M151.168 180.637H146.69V187.499C146.69 188.421 147.438 189.168 148.36 189.168C149.911 189.168 151.168 187.911 151.168 186.36V180.637Z" fill="#8C7371" />
      <mask id="mask9" mask-type="alpha" maskUnits="userSpaceOnUse" x="146" y="180" width="6" height="10">
        <path d="M151.168 180.637H146.69V187.499C146.69 188.421 147.438 189.168 148.36 189.168C149.911 189.168 151.168 187.911 151.168 186.36V180.637Z" fill="white" />
      </mask>
      <g mask="url(#mask9)">
        <path fillRule="evenodd" clipRule="evenodd" d="M152.368 187.377C152.368 187.377 149.127 187.927 146.227 183.394C143.328 178.861 148.929 179.338 148.929 179.338L152.368 183.921V187.377Z" fill="#9E8483" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M150.142 185.645C148.236 185.645 146.69 183.403 146.69 180.637C146.69 177.871 146.923 175.629 150.142 175.629C153.36 175.629 153.593 177.871 153.593 180.637C153.593 183.403 152.048 185.645 150.142 185.645Z" fill="#AC908E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M150.572 178.018C148.912 178.018 147.567 177.361 147.567 176.552C147.567 175.742 148.912 175.086 150.572 175.086C152.232 175.086 153.578 175.742 153.578 176.552C153.578 177.361 152.232 178.018 150.572 178.018Z" fill="#273741" />
      <path fillRule="evenodd" clipRule="evenodd" d="M146.692 181.935C146.022 181.935 145.479 181.354 145.479 180.637C145.479 179.92 146.022 179.339 146.692 179.339C147.362 179.339 147.905 179.92 147.905 180.637C147.905 181.354 147.362 181.935 146.692 181.935Z" fill="#977D7C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M135.475 197.116L134.183 199.948L136 203.443C136.619 204.632 138.364 204.488 138.779 203.214L140.106 199.147L135.475 197.116Z" fill="#927C7A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M137.082 189.931L134.327 198.722C134.327 198.722 135.106 199.925 136.555 200.542C138.004 201.159 140.261 200.542 140.261 200.542L142.122 192.325C142.122 192.325 141.785 188.714 140.261 187.99C138.737 187.266 137.082 189.931 137.082 189.931Z" fill="#FFEFD6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M134.673 205.19C135.606 205.997 137.011 205.92 137.849 205.016C138.46 204.357 138.629 203.401 138.281 202.572L132.769 189.465L130.918 189.965L133.039 202.824C133.157 203.541 133.523 204.194 134.072 204.669L134.673 205.19Z" fill="#AC908E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M154.004 206.638L157.684 205.113L157.911 206.187L154.231 208.023L154.004 206.638Z" fill="#FFEFD6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M141.061 207.849H145.667V209H141.061V207.849Z" fill="#FFEFD6" />
      <path fillRule="evenodd" clipRule="evenodd" d="M133 188.848C133 188.848 132.005 186.877 131.458 187.156C130.91 187.434 131.986 188.848 131.986 188.848H133Z" fill="#AC908E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M131.347 191.727C131.347 191.727 128.228 190.887 127.342 190.273C126.831 189.919 125.461 187.687 129.149 189.641C130.275 190.237 131.245 189.163 132.071 189.641C133.152 190.266 132.975 191.338 132.975 191.338L131.347 191.727Z" fill="#AC908E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M147.394 177.927L147.19 179.242H146.71L146.342 180.212H145.667L146.342 176.757L147.19 176.959L147.394 177.927Z" fill="#273741" />
      <path fillRule="evenodd" clipRule="evenodd" d="M171.601 207.594L175.223 207.642L176.788 210.102C176.972 210.391 176.788 210.774 176.446 210.809C176.309 210.823 176.172 210.775 176.074 210.678L174.822 209.44C174.646 209.266 174.378 209.222 174.155 209.33L174.075 209.369C173.751 209.527 173.68 209.957 173.937 210.21L174.212 210.482C174.416 210.684 174.294 211.032 174.008 211.061C173.939 211.069 173.87 211.054 173.809 211.021L171.061 209.508L171.601 207.594Z" fill="#AC908E" />
      <defs>
        <filter id="filter0_f" x="257.853" y="173.991" width="266.831" height="176.41" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9.29652" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter1_f" x="42.077" y="218.178" width="284.144" height="178.577" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9.29652" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter2_f" x="152.204" y="201.553" width="127.503" height="127.49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="11.3515" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter3_f" x="-26.6125" y="215.846" width="100.13" height="100.121" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="11.3515" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter4_f" x="267.695" y="198.662" width="101.78" height="92.5975" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.87149" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter5_f" x="237.547" y="61.2087" width="146.545" height="181.447" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.87149" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter6_f" x="283.105" y="229.691" width="58.8885" height="40.7993" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.93574" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter7_f" x="412.08" y="83.4186" width="67.7249" height="43.4877" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.93574" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter8_f" x="412.08" y="115.213" width="43.7273" height="31.0654" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.93574" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter9_f" x="53.3742" y="92.4698" width="69.0949" height="64.7227" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.93574" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter10_f" x="246.161" y="105.203" width="111.731" height="39.4409" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.93574" result="effect1_foregroundBlur" />
        </filter>
        <filter id="filter11_f" x="243.595" y="69.1266" width="42.9931" height="30.8558" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.93574" result="effect1_foregroundBlur" />
        </filter>
        <linearGradient id="paint0_linear" x1="143.059" y1="293.89" x2="143.059" y2="353.963" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="340.172" y1="130.967" x2="340.172" y2="252.136" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="-42.4693" y1="311.68" x2="16.6809" y2="388.433" gradientUnits="userSpaceOnUse">
          <stop stopColor="#96CEF2" />
          <stop offset="1" stopColor="#3D99DB" />
        </linearGradient>
        <linearGradient id="paint3_linear" x1="89.6987" y1="357.163" x2="274.863" y2="270.365" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D1EEFF" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint4_linear" x1="27.1516" y1="387.953" x2="275.965" y2="272.356" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D1EEFF" />
          <stop offset="1" stopColor="#73B5E2" />
        </linearGradient>
        <linearGradient id="paint5_linear" x1="465.36" y1="-236.738" x2="198.312" y2="-210.046" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D96022" />
          <stop offset="1" stopColor="#ED7F34" />
        </linearGradient>
        <linearGradient id="paint6_linear" x1="257.958" y1="364.925" x2="400.144" y2="362.006" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3BE88" />
          <stop offset="1" stopColor="#E88C3E" />
        </linearGradient>
        <linearGradient id="paint7_linear" x1="272.412" y1="291.583" x2="363.828" y2="285.566" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E88E3E" />
          <stop offset="1" stopColor="#EEA766" />
        </linearGradient>
        <linearGradient id="paint8_linear" x1="272.38" y1="291.536" x2="360.282" y2="285.847" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3BE88" />
          <stop offset="1" stopColor="#E88C3E" />
        </linearGradient>
        <linearGradient id="paint9_linear" x1="107.008" y1="76.7988" x2="107.008" y2="122.885" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9A320" />
          <stop offset="1" stopColor="#F06A0E" />
        </linearGradient>
        <linearGradient id="paint10_linear" x1="398.37" y1="140.341" x2="436.34" y2="162.022" gradientUnits="userSpaceOnUse">
          <stop stopColor="#96CEF2" />
          <stop offset="1" stopColor="#3D99DB" />
        </linearGradient>
        <linearGradient id="paint11_linear" x1="432.185" y1="87.6469" x2="438.884" y2="157.484" gradientUnits="userSpaceOnUse">
          <stop stopColor="#009CD8" />
          <stop offset="1" stopColor="#0169B4" />
        </linearGradient>
        <linearGradient id="paint12_linear" x1="37.1964" y1="150.284" x2="75.1671" y2="171.965" gradientUnits="userSpaceOnUse">
          <stop stopColor="#96CEF2" />
          <stop offset="1" stopColor="#3D99DB" />
        </linearGradient>
        <linearGradient id="paint13_linear" x1="71.0116" y1="97.5907" x2="77.7106" y2="167.428" gradientUnits="userSpaceOnUse">
          <stop stopColor="#009CD8" />
          <stop offset="1" stopColor="#0169B4" />
        </linearGradient>
        <linearGradient id="paint14_linear" x1="235.753" y1="215.814" x2="378.921" y2="209.333" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E88E3E" />
          <stop offset="1" stopColor="#EEA766" />
        </linearGradient>
        <linearGradient id="paint15_linear" x1="235.71" y1="215.735" x2="374.174" y2="209.573" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3BE88" />
          <stop offset="1" stopColor="#E88C3E" />
        </linearGradient>
        <linearGradient id="paint16_linear" x1="146.061" y1="265.629" x2="130.73" y2="241.573" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopOpacity="0.01" />
        </linearGradient>
      </defs>
    </svg>

  )
}

export default Person;
