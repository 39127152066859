import { useState, forwardRef, useMemo } from 'react';

import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  formWrapper: {
    display: 'grid',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr',
      gap: 60,
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: '1fr 1fr',
      gap: 0,
    },
  },
  formRow: {
    marginBottom: 20,
  },
  boxModal: {
    width: 700,
    [theme.breakpoints.down('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      width: 320,
    },
  }
}));

// @ts-ignore
const Alert = forwardRef(function Alert(props, ref) {
  // @ts-ignore
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface IForm {
  closeForm: () => void;
}
function Form({ closeForm }: IForm) {
  const styles = useStyles();
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [corporateEmail, setCorporateMail] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  const [snackOpen, setSnackOpen] = useState(false)
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)'), []);

  const handleSubmit = async () => {
    await fetch('https://demo-request.amaros.ai/requestDemo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        message,
        name,
        title,
        email: corporateEmail,
        company,
      }),
    });
    setName('');
    setTitle('');
    setCorporateMail('');
    setCompany('');
    setSnackOpen(true);
    setMessage('');
  };

  const handleClose = () => setSnackOpen(false);
  return (
    <>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* @ts-ignore */}
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Request sent
        </Alert>
      </Snackbar>

      <Box
        className={styles.boxModal}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: isMobile ? '15px' : '80px',
        }}>
        <IconButton onClick={closeForm} sx={{
          position: 'absolute',
          top: isMobile ? 15 : 20,
          right: isMobile ? 15 : 20,
        }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" color="textSecondary" sx={{ fontFamily: "'Montserrat', monospace", marginBottom: '45px' }}>
          Request Demo
        </Typography>
        <div className={styles.formWrapper}>
          <div className={styles.formRow}>
            <TextField
              size='small'
              onChange={e => setName(e.target.value)} type="email" fullWidth label="Name" variant="outlined" sx={{ marginBottom: '20px' }} value={name} />
            <TextField
              size='small'
              onChange={e => setTitle(e.target.value)} fullWidth label="Title" variant="outlined" value={title} />
          </div>
          <div className={styles.formRow}>
            <TextField
              size='small'
              onChange={e => setCorporateMail(e.target.value)} fullWidth label="Corporate Email" variant="outlined" sx={{ marginBottom: '20px' }} value={corporateEmail} />
            <TextField
              size='small'
              onChange={e => setCompany(e.target.value)} fullWidth label="Company" variant="outlined" value={company} />
          </div>
        </div>
        <TextField
          onChange={e => setMessage(e.target.value)}
          fullWidth
          label=""
          multiline
          maxRows={6}
          rows={6}
          variant="outlined"
          value={message}
        />
        <Button color="warning" variant="contained" sx={{ borderRadius: '0px', marginTop: '35px' }} onClick={handleSubmit}>Submit</Button>
      </Box>
    </>
  );
}

export default Form;
