import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import eye from '../assets/images/Eye.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  slogan: {
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    gap: 40,
    padding: '75px 0 110px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: '1fr 1fr',
      padding: '20px 0px',
    },
  },
  text: {
    fontFamily: "'Montserrat', monospace",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: '1fr 1fr',
      padding: '0px 20px',
    },
  },
  imageHolder: {
    width: '100%',
    '& img': {
      width: '100%',
      height: '100%',
    },
  }
}));


function Slogan() {
  const styles = useStyles();
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);

  return (
    <section className={styles.slogan}>
      <div className={styles.imageHolder}><img src={eye} alt="eye ilustration" /></div>
      <div className={styles.text}>
        <Typography color="primary" variant={isMobile ? "h6" : "h4"} sx={{ fontFamily: "'Montserrat', monospace", }}>
          Leveraging Real-World Data  <br />
          & AI to Transform Clinical <br />
          Trials in Ophthalmology
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" sx={{ fontFamily: "'Montserrat', monospace", marginTop: '15px' }}>
          Amaros has developed a novel technology that collects, <br />
          generates, structures, and analyzes vast amounts of data <br />
          and merges with the power of AI to facilitate the development<br />
          of optimal therapeutics for tomorrow.
        </Typography>
      </div>
    </section>
  );
}

export default Slogan;
