import { useRef } from 'react';

import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

const useStyles = makeStyles((theme: Theme) => ({
  testimonials: {
    background: '#006D75',
    paddingTop: 75,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
    },
  },
  testimonialsList: {
    overflowX: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 435px)',
    gap: 50,
    padding: '10px 60px 62px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      padding: 20,
      gap: 25,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(5, 320px)',
      gap: 25,
      padding: 20,
    },

  },
  testimonialItem: {
    padding: '60px 55px 50px',
    background: '#fff',
    display: 'grid',
    gap: 60,
    [theme.breakpoints.up('sm')]: {
      gridTemplateRows: '70% 30%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 10,
      gridTemplateRows: '70% 30%',
      gap: 40,

    },
  },
  title: {
    width: 1170,
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      padding: 20,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 20,
    },
  },
  scrollButtonLeft: {
    left: 0,
    top: 0,
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'none !important',
    },
  },
  scrollButtonRight: {
    right: 0,
    top: 0,
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'none !important',
    },
  }
}));
function Testimonials() {
  const styles = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  return (
    <section className={styles.testimonials}>
      <Button size="large"
        sx={{ position: 'absolute', minWidth: '60px', '&:hover': { background: 'rgba(0, 109, 117, 0.8)' }, background: 'rgba(0,0,0, 0.1)', }}
        className={styles.scrollButtonLeft}
        onClick={() => ref.current?.scrollTo({
          left: 0,
          behavior: 'smooth'
        })}
      >
        <ChevronLeft fontSize="large" />
      </Button>
      <Button
        sx={{ position: 'absolute', minWidth: '60px', '&:hover': { background: 'rgba(0, 109, 117, 0.8)' }, background: 'rgba(0,0,0, 0.1)', }}
        className={styles.scrollButtonRight}
        size="large"
        onClick={() => ref.current?.scrollTo({
          left: 800,
          behavior: 'smooth'
        })}
      >
        <ChevronRight fontSize="large" />
      </Button>
      <div className={styles.title}>
        <Typography variant="h4" color="white" sx={{ fontFamily: "'Montserrat', monospace", padding: '20px 0', marginBottom: '45px' }}>
          Testimonials
        </Typography>
      </div>
      <div className={styles.testimonialsList} ref={ref}>
        <div className={styles.testimonialItem}>
          <Typography color="primary" sx={{ fontFamily: "'Montserrat', monospace" }}>
            “The AmarosAI platform can significantly improve clinical study enrollment efficiency.
            The software has the potential to make studies more successful
            in meeting endpoints while being much more time and cost effective.”
          </Typography>
          <div>
            <Typography color="black" fontWeight={700}>Neil Friedman, MD</Typography>
            <Typography color="textSecondary">Adjunct Clinical Professor of Ophthalmology, Stanford University School of Medicine</Typography>
          </div>
        </div>
        <div className={styles.testimonialItem}>
          <Typography color="primary" sx={{ fontFamily: "'Montserrat', monospace" }}>
            ”My practice is involved in a variety of studies in ocular surface diseases.
            We see a lot of potential in a clinical study enrollment software that can help us take the
            labor out of identifying patients who might meet the enrollment criteria, as this is currently
            a manual process. By having a technology perform this task, we can focus our personnel efforts
            on preparing for and executing the studies at a high quality level.”
          </Typography>
          <div>
            <Typography color="black" fontWeight={700}>Paul Karpecki, OD</Typography>
            <Typography color="textSecondary">Associate Professor, University of Pikeville, Kentucky College of Optometry</Typography>
          </div>
        </div>
        <div className={styles.testimonialItem}>
          <Typography color="primary" sx={{ fontFamily: "'Montserrat', monospace" }}>
            “I am enthusiastic about novel technology that utilizes artificial
            intelligence and machine learning to make clinical trial enrollment
            more efficient and successful. The time is right for this technology,
            as the costs for clinical trials continue to increase and clinical sites
            are seeking to improve efficiency and decrease study cost and labor required.
            The Amaros AI-based software is just the ideal technology that can achieve this goal.”
          </Typography>
          <div>
            <Typography color="black" fontWeight={700}>T.Y. Alvin Liu, MD</Typography>
            <Typography color="textSecondary">Director, John Hopkins Wilmer Precision Ophthalmology Center of Excellence</Typography>
          </div>
        </div>
        <div className={styles.testimonialItem}>
          <Typography color="primary" sx={{ fontFamily: "'Montserrat', monospace" }}>
            ”I believe an AI-based clinical studies tool to increase enrollment
            is an excellent idea for a large organization like Prism Vision Group.
            This tool will help practices find more potential candidates to not only
            meet but exceed enrollment for both posterior and anterior segments of the eye.”
          </Typography>
          <div>
            <Typography color="black" fontWeight={700}>Cynthia Matossian, MD</Typography>
            <Typography color="textSecondary">Founder, Chief Executive Officer of Matossian Eye Associates (Prisim Vision)</Typography>
          </div>
        </div>
        <div className={styles.testimonialItem}>
          <Typography color="primary" sx={{ fontFamily: "'Montserrat', monospace" }}>
            ”We run a large number of clinical studies through our site each year.
            We are very excited to utilize the Amaros AI-based technology to help us improve our
            enrollment time and success, while also enhancing the likelihood of success of the trial
            in meeting its endpoints. AI-enhanced enrollment is the future of clinical study execution,
            and we are glad to be on the cutting edge of technological advancements in this area.”
          </Typography>
          <div>
            <Typography color="black" fontWeight={700}>Vance Thompson, MD FACS</Typography>
            <Typography color="textSecondary">Co-Founder. The Founder of Vance Thompson Vision, Sioux Falls, SD, and the Director of Refractive Surgery </Typography>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
