import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import logo from '../assets/images/logo.png';

import Person from './Person';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme: Theme) => ({

  footer: {
    paddingTop: '32px',
    paddingBottom: '41px',
    [theme.breakpoints.down('lg')]: {
      padding: 20,
    },
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    maxWidth: 1170,
    width: '100%',
    margin: '0 auto',
  },
  preFooter: {
    backgroundColor: theme.palette.primary.dark,
  },
  dontWait: {
    maxWidth: 1170,
    width: '100%',
    margin: '0 auto',
    padding: '60px 0',
    display: 'grid',
    [theme.breakpoints.down('lg')]: {
      padding: 20,
    },
    [theme.breakpoints.down('md')]: {
      padding: 20,
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: '70% 30%',
      padding: 20,
    },

  },
  requestBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'self-start',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      order: 2
    },
  },
}));


function Footer({ openRequestDemo }: { openRequestDemo: () => void }) {
  const styles = useStyles();
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);


  return (
    <>
      <section className={styles.preFooter}>
        <div className={styles.dontWait}>
          <div className={styles.requestBlock}>
            <Typography variant={isMobile ? "h6" : "h4"} color="#fff" sx={{ fontFamily: "'Montserrat', monospace", marginBottom: '45px' }}>
              Ready to see it in action ?
            </Typography>
            <Button size="large" color="warning" onClick={openRequestDemo} variant="contained" sx={{ borderRadius: '0px' }}>Request a demo</Button>
          </div>
          <Person />
        </div>
      </section>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <Typography color="#fff">
            <img width={140} src={logo} alt="" />
          </Typography>
          <Typography color="#fff" sx={{ opacity: '.8', margin: '15px 0' }}>
            © {new Date().getFullYear()} Amaros Inc.
          </Typography>
        </div>
      </footer>
    </>
  )
}

export default Footer;
