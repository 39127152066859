import { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';

import SlidingText from './SlidingText';

const useStyles = makeStyles((theme: Theme) => ({
  intro: {
    padding: '178px 40px 117px',
    background: '#006D75',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '128px 20px 20px',
    },
  },
  intro2: {
    padding: 30,
    height: 2000,
  },
  floatingTextContent: {
    width: 1170,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      gridTemplateRows: '1fr 122px 43px ',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gridTemplateRows: '1fr 122px 43px ',

    },
    textAlign: 'left',
    margin: '0 auto',
    display: 'grid',
    gridTemplateRows: '1fr 56px 43px ',
    gap: 20,
  },
}));

function Intro({ openRequestDemo }: { openRequestDemo: () => void }) {
  const styles = useStyles();
  const isMobile = useMemo(() => window.matchMedia('(max-width: 600px)').matches, []);
  const isIpad = useMemo(() => window.matchMedia('(min-device-width: 600px) and (max-device-width: 1200px)').matches, []);

  return (
    <div className={styles.intro}>
      <section className={styles.floatingTextContent}>
        <Typography
          color="inherit"
          variant={
            isMobile ? "subtitle1"
              : isIpad
                ? 'h4'
                : "h2"}
          sx={{
            letterSpacing: '-1px',
            fontWeight: '700',
            margin: '0 0 25px',
            textTransform: 'uppercase',
            maxWidth: 1100,
          }}
        >
          We  <SlidingText /> CLINICAL TRIALS <br />
          FOR SITE, CRO & PHARMA.
        </Typography>
        <Typography color="inherit" variant={isMobile ? "subtitle1" : 'h6'} >
          Amaros is building the most advanced real-world data (RWD)
          <br />
          and real-world evidence (RWE) platform in ophthalmology.
        </Typography>
        <Button
          variant="contained"
          color="warning"
          sx={{
            borderRadius: '0px',
            maxWidth: 300,
            '&:hover': {
              backgroundColor: '#fff',
              color: 'primary.main',
            }
          }}
          size="large"
          onClick={openRequestDemo}
        >
          Request Demo
        </Button>
      </section>
    </div>
  )
}

export default Intro;
