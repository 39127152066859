import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import eye from '../assets/images/eye3.png';

import Check from './Check';

const useStyles = makeStyles((theme: Theme) => ({
  benefitsWrapper: {
    background: '#006D75',
  },
  benefits: {
    display: 'grid',
    gridTemplateRows: '1fr 290px',
    maxWidth: 960,
    margin: '0 auto',
    paddingBottom: 30,
    position: 'relative',
    gap: 20,
    paddingTop: 30,
    justifyContent: 'center',
  },
  benefitsTop: {
    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '40% 60%',
      gap: 0,
    },
    justifyContent: 'center',
    gap: 30,
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: '65% 35%',
    },
  },
  benefitsList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: 30,
    [theme.breakpoints.down('md')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.down('sm')]: {
      overflowX: 'scroll',
      padding: '0 20px',
    },
  },
  benefititem: {
    padding: '30px 20px',
    border: `1px dashed ${theme.palette.warning.main}`,
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      minWidth: '300px',
    },
  },
  imageHolder: {
    width: '100%',
    '& img': {
      width: '100%',
    }
  },
  imageText: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
}));

function Benefits() {
  const styles = useStyles();
  return (
    <div className={styles.benefitsWrapper}>
      <section className={styles.benefits}>
        <div className={styles.benefitsTop}>
          <div className={styles.imageHolder}><img src={eye} alt="ilustration" /></div>
          <div className={styles.imageText}>
            <Typography color="#fff" variant="h4" sx={{ fontFamily: "'Montserrat', monospace" }}>
              Amaros' transformative  <br />
              IPA&trade; platform
            </Typography>
          </div>
        </div>
        <div className={styles.benefitsList}>
          <div className={styles.benefititem}>
            <div style={{ marginBottom: 20 }}><Check /></div>
            <Typography color="white" sx={{ marginBottom: '10px' }} variant="h6" fontWeight="700">Identify</Typography>
            <Typography>
              Increase and accelerate clinical trial patient recruitment in real-time
            </Typography>
            <Typography>
              with real-world data(RWD)
            </Typography>
          </div>
          <div className={styles.benefititem}>
            <div style={{ marginBottom: 20 }}><Check /></div>
            <Typography color="white" sx={{ marginBottom: '10px' }} variant="h6" fontWeight="700">Predict</Typography>
            <Typography>
              Forecast disease progression and treatment impact for studies and expand the market for approved therapeutics
            </Typography>
          </div>
          <div className={styles.benefititem}>
            <div style={{ marginBottom: 20 }}><Check /></div>
            <Typography color="white" sx={{ marginBottom: '10px' }} variant="h6" fontWeight="700">Analyze</Typography>
            <Typography>
              Leverage longitudinal & continuous data records to facilitate pharmacovigilance & therapeutic development
            </Typography>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Benefits;
