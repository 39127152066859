function Check() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z" stroke="url(#paint0_linear)" strokeWidth="2" strokeLinejoin="round" />
      <path d="M13.9599 18.9708L20.1236 25.1345L30.2386 15.0196" stroke="url(#paint1_linear)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <linearGradient id="paint0_linear" x1="37.2256" y1="-33.3303" x2="-31.4351" y2="6.66967" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D2B8" />
          <stop offset="0.30842" stopColor="#ED9152" />
          <stop offset="1" stopColor="#D22A00" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="38.3382" y1="5.75679" x2="21.2856" y2="-2.36044" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F9D2B8" />
          <stop offset="0.30842" stopColor="#ED9152" />
          <stop offset="1" stopColor="#D22A00" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Check;
